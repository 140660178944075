import React from "react";
import styles from "./index.module.scss";
import global from "../index.module.scss";

const ErrorScreen = ({ setScreenNumber }) => {
  return (
    <div className={styles.errorScreen}>
      <p>404</p>
      <p>Упс...</p>
      <p>Такої сторінки не існує.</p>
      <button className={global.menuButton} onClick={() => setScreenNumber(1)}>
        Повернутися на головну
      </button>
    </div>
  );
};

export default ErrorScreen;
