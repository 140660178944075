import React from 'react';
import style from './index.module.scss';
import global from '../../index.module.scss';
import HeadContainer from '../../head-container';
import { useMemo } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';

export default function DailyTasksChallenge({
  dailyTasksList,
  dailyTasksListCount,
  acceptChallengeCopy,
  setScreenNumber,
  backgroundImage,
  title
}) {
  const { sendEvent } = useContext(DataLayerEventContext);
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState();

  const btnCopy = useMemo(() => {
    if (!task) return 'Старт';
    return acceptChallengeCopy;
  }, [task, acceptChallengeCopy]);

  const onClickHandler = useCallback(() => {
    if (task) {
      sendEvent('accept');
      setScreenNumber(8);
      return;
    }
    setLoading(true);
    sendEvent('start');

    setTimeout(() => {
      const number = Math.floor(Math.random() * dailyTasksListCount + 1);
      const task = dailyTasksList[number];
      setTask(task);
      setLoading(false);
    }, 2000);
  }, [sendEvent, dailyTasksList, setScreenNumber, dailyTasksListCount, task]);
  return (
    <div
      className={style.DailyTasksChallenge}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={style.DailyTasksChallengeWrapper}>
        <div className={style.DailyTasksChallengeTitle}>
          <h2>{title}</h2>
        </div>
        <div className={style.DailyTasksChallengeTaskContainer}>
          <div className={style.TaskBlock}>
            <div className={style.TaskBlockInner}>
              <TaskCopy loading={loading} task={task} />
            </div>
          </div>
          <div>
            <button className={global.acceptButton} onClick={onClickHandler}>
              {btnCopy}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function TaskCopy({ loading, task }) {
  if (loading)
    return (
      <div className={style.DotsLoading}>
        <div className={style.Dot1} />
        <div className={style.Dot2} />
        <div className={style.Dot3} />
      </div>
    );
  if (task) return <p>{task}</p>;
  return <p style={{ fontSize: '118px' }}>?</p>;
}
