import React, { useCallback, useContext } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../components/lm-d-s/head-container';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';
import classNames from 'classnames';

const MenuScreen = ({
  setScreenNumber,
  menuList,
  backgroundImage,
  isBlack = false
}) => {
  const { sendEvent } = useContext(DataLayerEventContext);

  const onMenuItemClick = useCallback(
    (item) => {
      setScreenNumber(item.screenNumber);

      sendEvent(item.id);
    },
    [setScreenNumber, sendEvent]
  );

  return (
    <div
      className={styles.menuContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <div className={styles.content}>
        <HeadContainer setScreenNumber={setScreenNumber} />
        <div className={styles.buttonContainer}>
          {menuList.map((item, index) => (
            <div
              className={classNames(
                styles.buttonItem,
                isBlack && styles.blackButtonItem
              )}
              style={{ animationDelay: `${index * 0.1}s` }}
              key={item.id}
              onClick={() => onMenuItemClick(item)}
            >
              <div>
                <img src={item.icon} alt="menu-chat" />
              </div>
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuScreen;
