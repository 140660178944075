import React, { useEffect, useState } from 'react';
import StartScreen from './start-screen';
import ChoiceScreen from './choice-screen';
import FeedbackScreen from './feedback-screen';

export default function AdventureChallenge({
  setScreenNumber,
  backgroundImage
}) {
  const [status, setStatus] = useState('start'); // start | in_progress | finished
  const [step, setStep] = useState(1);
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const { gameQuestionList, quizResultList } = await import(
        `./make-your-story.js`
      );
      const [_gameQuestionList, _quizResultList] = await Promise.all([
        gameQuestionList,
        quizResultList
      ]);
      setData({
        gameQuestionList: _gameQuestionList,
        quizResultList: _quizResultList
      });
    })();
  }, []);

  if (status === 'start')
    return (
      <StartScreen
        setScreenNumber={setScreenNumber}
        backgroundImage={backgroundImage}
        setNextScreenNumber={() => {
          setStatus('in_progress');
          setStep(1);
        }}
      />
    );
  if (status === 'in_progress')
    return (
      <ChoiceScreen
        list={data?.gameQuestionList}
        resultList={data?.quizResultList}
        setScreenNumber={setScreenNumber}
        backgroundImage={backgroundImage}
        step={step}
        setStep={setStep}
        setStatus={setStatus}
      />
    );
  if (status === 'finished')
    return (
      <FeedbackScreen
        setScreenNumber={setScreenNumber}
        setNextScreenNumber={() => setStatus('start')}
        backgroundImage={backgroundImage}
      />
    );
}
