import React, { useState, useCallback, useContext, useEffect } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../common/head-container';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';
import classNames from 'classnames';

export default function ChoiceScreen({
  list,
  resultList,
  setScreenNumber,
  backgroundImage,
  setStep,
  step,
  setStatus
}) {
  const { sendEvent } = useContext(DataLayerEventContext);
  const [industry, setIndustry] = useState('');
  const [question, setQuestion] = useState(list[0]);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [flow, setFlow] = useState('1');
  const [result, setResult] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [previousQuestions, setPreviousQuestions] = useState([]);

  useEffect(() => {
    setCurrentAnswer((prev) => prev);
  }, [currentAnswer]);

  useEffect(() => {
    if (step == 1) {
      setQuestion(list[0]);
      setFlow('1');
      const industryChoice = currentAnswer === 1 ? 'bank' : 'IT';
      setIndustry(industryChoice);
    }
    let updatedFlow = flow + '-' + currentAnswer;
    let selectedResult = resultList.find((el) => el.id === updatedFlow);
    setResult(selectedResult);
  }, [step, currentAnswer]);

  useEffect(() => {
    rememberCurrentQuestion(question.id);
  }, [question]);

  const rememberCurrentQuestion = useCallback(
    (id) => {
      setPreviousQuestions((prev) =>
        [...prev, id].filter(
          (value, index) => [...prev, id].indexOf(value) === index
        )
      );
    },
    [step]
  );

  const onNextStepClick = () => {
    if (step <= 5 && currentAnswer !== null) {
      setStep((prev) => prev + 1);
      setFlow((f) => f + '-' + currentAnswer);
      setShowResult(result);
      if (!result) {
        setQuestion(
          list.find(
            (el) =>
              el.step === step + 1 &&
              el.previousAnswear === currentAnswer &&
              el.industry === industry
          )
        );
      }
      return;
    }
  };

  const onPrevStepClick = () => {
    if (step > 1) {
      previousQuestions.pop();
      const prev = list.find(
        (el) => el.id === previousQuestions[previousQuestions.length - 1]
      );
      setQuestion(prev);
      setStep((prev) => prev - 1);
      setFlow((f) => f.substring(0, f.length - 2));
      return;
    }
  };

  return (
    <div
      className={styles.choiceScreenContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      {showResult ? (
        <Result
          result={result}
          sendEvent={sendEvent}
          backgroundImage={backgroundImage}
          setScreenNumber={setScreenNumber}
          setStatus={setStatus}
        />
      ) : (
        <Questions
          step={step}
          question={question}
          onNextStepClick={onNextStepClick}
          onPrevStepClick={onPrevStepClick}
          setCurrentAnswer={setCurrentAnswer}
        />
      )}
    </div>
  );
}

function Result({ setStatus, result, sendEvent }) {
  const [propResult, setPropResult] = useState(result);
  const onClick = useCallback(() => {
    setStatus('finished');
    sendEvent('end_challenge');
  }, [sendEvent, setStatus]);
  return (
    <>
      {propResult && (
        <div className={styles.resultScreenContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.imageContainer}>
              <img
                src="/images/adventure/ellipse_light.png"
                alt="blue-ellipse"
                className={styles.ellipse}
              />
              <img
                src="/images/adventure/result.png"
                alt="man"
                className={styles.resultImg}
              />
            </div>
            <p className={styles.resultTitle}>фінал</p>
            <p
              className={styles.resultText}
              dangerouslySetInnerHTML={{ __html: propResult.title }}
            ></p>
            <button
              className={classNames(styles.button, global.menuButton)}
              onClick={onClick}
            >
              Далі
            </button>
          </div>
        </div>
      )}
    </>
  );
}

function Questions({
  onNextStepClick,
  onPrevStepClick,
  setCurrentAnswer,
  question,
  step
}) {
  return (
    <>
      <div className={styles.contentContainer}>
        {
          <div className={styles.stepToolbar}>
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                className={classNames(
                  styles.stepItem,
                  index + 1 === step && styles.activeStepItem
                )}
              />
            ))}
          </div>
        }
        <p
          className={styles.question}
          dangerouslySetInnerHTML={{ __html: question.question }}
        ></p>
        <div className={styles.answers}>
          {question.answers.map((item) => (
            <div
              className={styles.answer}
              key={item.key}
              onClick={() => setCurrentAnswer(item.key)}
            >
              <div>{item.key}</div>
              <p>{item.value}</p>
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          <button
            className={classNames(styles.button, global.menuButton)}
            onClick={() => onPrevStepClick()}
          >
            Назад
          </button>
          <button
            className={classNames(styles.button, global.menuButton)}
            onClick={() => onNextStepClick()}
          >
            Далі
          </button>
        </div>
      </div>
    </>
  );
}
