import React, { useState, useEffect } from 'react';
import './default.css';
import styles from './App.module.css';
import './common/index.module.scss';
import SessionEndScreen from './components/session-end-screen/SessionEndScreen';
import { ScreensContext } from './contexts/screensContext';
import { TypeFormContext } from './contexts/typeFormContext';
import { isMobile } from './utils/common';
import LMDS from './pages/LM-D-S';
import LM4 from './pages/LM-4';
import Chesterfield6 from './pages/Chesterfield-6';
import Chesterfield4 from './pages/Chesterfield-4';
import PMNS from './pages/PMNS';
import PMNB from './pages/PMNB';
import PMNM from './pages/PMNM';
import PMNMS from './pages/PMNMS';
import { DataLayerEventProvider } from './contexts/dataLayerEventContext';
import BNDP4 from './pages/BND-P-4';
import BNDP6 from './pages/BND-P-6';
import BNDPM from './pages/BND-P-M';
import MLBT from './pages/MLB-T';
import MLBFT from './pages/MLB-F-T';
import PRL_S_6 from './pages/PRL_S_6';
import PRL_S_4 from './pages/PRL_S_4';
import PRL_V from './pages/PRL_V';
import PRL_S_6_Test from './pages/PRL_S_6_Test';
import PRL_S_4_Test from './pages/PRL_S_4_Test';
import PRL_V_Test from './pages/PRL_V_Test';
import MLBTTest from './pages/MLB-T-Test';
import MLBFTTest from './pages/MLB-F-T-Test';
import LMLM from './pages/LM-L-M';
import LM6 from './pages/LM-6';
import LMLGREEN from './pages/LM-L-Green';
import LMLPurple from './pages/LM-L-Pr';
import ChesRe from './pages/Ches-Re';
import MPMPR from './pages/MPM-PR';
import KV from './pages/KV';
import LMDSTest from './pages/LM-D-S-TEST';
import Chesterfield6Test from './pages/Ches-6-Test';
import Chesterfield4Test from './pages/Ches-4-Test';
import GHWS from './pages/GHW-S';
import PersonalDataPolicy from './pages/PersonalDataPolicy';
import HSTSS1 from './pages/HSTSS1';
import HSTSS2 from './pages/HSTSS2';
import BNDB from './pages/BND-B';
import BNDR from './pages/BND-R';
import LMB from './pages/LM-B';
import LMR from './pages/LM-R';
import MLBG from './pages/MLB-G';
import MLBR from './pages/MLB-R';
import MLBS from './pages/MLB-S';
import ChesO from './pages/Ches-O';
import ChesB from './pages/Ches-B';
import ChesSP from './pages/Ches-SP';
import ChesSR from './pages/Ches-SR';
import LMSP from './pages/LM-SP';
import LMSR from './pages/LM-SR';
import MLBTLE from './pages/MLB-T-LE';
import MLBFTLE from './pages/MLB-F-T-LE';
import KV2 from './pages/KV2';
import ChesONB from './pages/Ches-O-NB';
import LMSPL from './pages/LM-SPL';

const getApp = (number, cb) => {
  switch (number) {
    case 1:
      return <LMLM setAppNumber={cb} />;
    case 2:
      return <LM6 setAppNumber={cb} />;
    case 3:
      return <LMLGREEN setAppNumber={cb} />;
    case 4:
      return <LMLPurple setAppNumber={cb} />;
    case 5:
      return <ChesRe setAppNumber={cb} />;
    case 6:
      return <MPMPR setAppNumber={cb} />;
    case 7:
      return <KV setAppNumber={cb} />;
    case 8:
      return <GHWS setAppNumber={cb} />;
    case 8.1:
      return <GHWS setAppNumber={cb} />;
    case 9:
      return <PersonalDataPolicy setAppNumber={cb} />;
    case 10:
      return <HSTSS1 setAppNumber={cb} />;
    case 11:
      return <HSTSS2 setAppNumber={cb} />;
    case 12:
      return <BNDB setAppNumber={cb} />;
    case 13:
      return <BNDR setAppNumber={cb} />;
    case 14:
      return <LMB setAppNumber={cb} />;
    case 15:
      return <LMR setAppNumber={cb} />;
    case 16:
      return <MLBG setAppNumber={cb} />;
    case 17:
      return <MLBR setAppNumber={cb} />;
    case 18:
      return <MLBS setAppNumber={cb} />;
    case 19:
      return <ChesO setAppNumber={cb} />;
    case 20:
      return <ChesB setAppNumber={cb} />;
    case 21:
      return <ChesSP setAppNumber={cb} />;
    case 22:
      return <ChesSR setAppNumber={cb} />;
    case 23:
      return <LMSP setAppNumber={cb} />;
    case 24:
      return <LMSR setAppNumber={cb} />;
    case 25:
      return <MLBTLE setAppNumber={cb} />;
    case 26:
      return <MLBFTLE setAppNumber={cb} />;
    case 27:
      return <LMDS setAppNumber={cb} />;
    case 27.1:
      return <LMDSTest setAppNumber={cb} />;
    case 28:
      return <Chesterfield6 setAppNumber={cb} />;
    case 28.1:
      return <Chesterfield6Test setAppNumber={cb} />;
    case 29:
      return <Chesterfield4 setAppNumber={cb} />;
    case 29.1:
      return <Chesterfield4Test setAppNumber={cb} />;
    case 30:
      return <PMNS setAppNumber={cb} />;
    case 31:
      return <PMNB setAppNumber={cb} />;
    case 32:
      return <PMNM setAppNumber={cb} />;
    case 33:
      return <PMNMS setAppNumber={cb} />;
    case 34:
      return <BNDP4 setAppNumber={cb} />;
    case 35:
      return <BNDP6 setAppNumber={cb} />;
    case 36:
      return <BNDPM setAppNumber={cb} />;
    case 37:
      return <MLBT setAppNumber={cb} />;
    case 38:
      return <MLBFT setAppNumber={cb} />;
    case 39:
      return <PRL_S_6 setAppNumber={cb} />;
    case 40:
      return <PRL_S_4 setAppNumber={cb} />;
    case 41:
      return <PRL_V setAppNumber={cb} />;
    case 42:
      return <PRL_S_6_Test setAppNumber={cb} />;
    case 43:
      return <PRL_S_4_Test setAppNumber={cb} />;
    case 44:
      return <PRL_V_Test setAppNumber={cb} />;
    case 45:
      return <MLBTTest setAppNumber={cb} />;
    case 46:
      return <MLBFTTest setAppNumber={cb} />;
    case 47:
      return <LM4 setAppNumber={cb} />;
    case 48:
      return <KV2 setAppNumber={cb} />;
    case 49:
      return <ChesONB setAppNumber={cb} />;
    case 50:
      return <LMSPL setAppNumber={cb} />;
    default:
      return <SessionEndScreen setAppNumber={cb} />;
  }
};

function App() {
  const [appNumber, setAppNumber] = useState(null);
  const [endSessionType, setEndSessionType] = useState('NOT_MOBILE');
  const [typeFormScreen, setTypeFormScreen] = useState('Смак та якість тютюну');
  const [isLocalQrCode, setIsQrCode] = useState(
    sessionStorage.getItem('isQrCode')
  );

  useEffect(() => {
    if (
      isLocalQrCode &&
      appNumber &&
      window.location.href.includes(isLocalQrCode)
    ) {
      window.location.replace(window.location.origin);
    }
  }, [isLocalQrCode, appNumber]);

  useEffect(() => {
    if (
      !isMobile &&
      isLocalQrCode !== '24UA-hstss1' &&
      isLocalQrCode !== '24UA-hstss2'
    ) {
      return setAppNumber(null);
    }

    switch (isLocalQrCode) {
      case '23UA-LM-L-M':
        return setAppNumber(1);
      case '23UA-LM-6':
        return setAppNumber(2);
      case '24UA-LM-L-green':
        return setAppNumber(3);
      case '24UA-LM-L-Pr':
        return setAppNumber(4);
      case '24UA-Ches-Re':
        return setAppNumber(5);
      case '24UA-MPM-PR':
        return setAppNumber(6);
      case '24UA-KV':
        return setAppNumber(7);
      case '24UA-GHW-S':
        return setAppNumber(8);
      case '24UA-GHW-S-Test':
        return setAppNumber(8.1);
      case 'personal-data-policy':
        return setAppNumber(9);
      case '24UA-hstss1':
        return setAppNumber(10);
      case '24UA-hstss2':
        return setAppNumber(11);
      case '24UA-BND-B':
        return setAppNumber(12);
      case '24UA-BND-R':
        return setAppNumber(13);
      case '24UA-LM-B':
        return setAppNumber(14);
      case '24UA-LM-R':
        return setAppNumber(15);
      case '24UA-MLB-G':
        return setAppNumber(16);
      case '24UA-MLB-R':
        return setAppNumber(17);
      case '24UA-MLB-S':
        return setAppNumber(18);
      case '24UA-Che-O':
        return setAppNumber(19);
      case '24UA-Che-B':
        return setAppNumber(20);
      case '24UA-Ches-SP':
        return setAppNumber(21);
      case '24UA-Ches-SR':
        return setAppNumber(22);
      case '24UA-LM-SP':
        return setAppNumber(23);
      case '24UA-LM-SR':
        return setAppNumber(24);
      case '24UA-MLB-T-LE':
        return setAppNumber(25);
      case '24UA-MLB-F-T-LE':
        return setAppNumber(26);
      case '23UA-LM-D-S':
        return setAppNumber(27);
      case '23UA-LM-D-S-Test':
        return setAppNumber(27.1);
      case '23UA-Ches-6':
        return setAppNumber(28);
      case '23UA-Ches-6-test':
        return setAppNumber(28.1);
      case '23UA-Ches-4':
        return setAppNumber(29);
      case '23UA-Ches-4-test':
        return setAppNumber(29.1);
      case '23UA-MPM-4-Test':
        return setAppNumber(30);
      case '23UA-MPM-6-Test':
        return setAppNumber(31);
      case '23UA-MPM-N-M-Test':
        return setAppNumber(32);
      case '23UA-MPM-N-M-S-Test':
        return setAppNumber(33);
      case '23UA-MPM-4':
        return setAppNumber(30);
      case '23UA-MPM-6':
        return setAppNumber(31);
      case '23UA-MPM-N-M':
        return setAppNumber(32);
      case '23UA-MPM-N-M-S':
        return setAppNumber(33);
      case '23UA-BND-P-4-Test':
        return setAppNumber(34);
      case '23UA-BND-P-4':
        return setAppNumber(34);
      case '23UA-BND-P-6-Test':
        return setAppNumber(35);
      case '23UA-BND-P-6':
        return setAppNumber(35);
      case '23UA-BND-P-M-Test':
        return setAppNumber(36);
      case '23UA-BND-P-M':
        return setAppNumber(36);
      case '23UA-MLB-T-Test':
        return setAppNumber(45);
      case '23UA-MLB-T':
        return setAppNumber(37);
      case '23UA-MLB-F-T-Test':
        return setAppNumber(46);
      case '23UA-MLB-F-T':
        return setAppNumber(38);
      case '23UA-PRL-S-6-Test':
        return setAppNumber(42);
      case '23UA-PRL-S-4-Test':
        return setAppNumber(43);
      case '23UA-PRL-V-Test':
        return setAppNumber(44);
      case '23UA-PRL-S-6':
        return setAppNumber(39);
      case '23UA-PRL-S-4':
        return setAppNumber(40);
      case '23UA-PRL-V':
        return setAppNumber(41);
      case '23UA-LM-4':
        return setAppNumber(47);
      case '24UA-KV2':
        return setAppNumber(48);
      case '24UA-Ches-O-NB':
        return setAppNumber(49);
      case '24UA-LM-Spl':
        return setAppNumber(50);
      default:
        setEndSessionType('OPEN_NOT_FROM_QR');
        setAppNumber(null);
        setIsQrCode('');
        return;
    }
  }, [isLocalQrCode]);

  return (
    <div className={styles.appBox}>
      <TypeFormContext.Provider value={{ typeFormScreen, setTypeFormScreen }}>
        <ScreensContext.Provider value={{ endSessionType, setEndSessionType }}>
          <DataLayerEventProvider>
            {getApp(appNumber, setAppNumber)}
          </DataLayerEventProvider>
        </ScreensContext.Provider>
      </TypeFormContext.Provider>
    </div>
  );
}

export default App;
