import React, { useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import global from '../../../../common/index.module.scss';
import HeadContainer from '../../../../components/lm-d-s/head-container';

const YourTopSelectChallenge = ({
  setScreenNumber,
  setAfterResultScreen,
  challengeList,
  backgroundImage
}) => {
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [challengeStep, setChallengeStep] = useState(1);
  const [isResultView, setIsResultView] = useState(false);
  const [history, setHistory] = useState([
    { options: [challengeList[0], challengeList[1]], selected: null }
  ]);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSelection, setCurrentSelection] = useState(null);
  const [usedOptions, setUsedOptions] = useState(
    new Set([challengeList[0].id, challengeList[1].id])
  );
  const [result, setResult] = useState(null);

  const handleOptionClick = (option) => {
    setCurrentSelection(option);
  };

  const handleNextClick = () => {
    if (currentSelection) {
      const availableOptions = challengeList?.filter(
        (o) => !usedOptions.has(o.id) && o.id !== currentSelection.id
      );
      if (availableOptions.length === 0) {
        setIsResultView(true);
        setResult(currentSelection);
      }
      const newOption =
        availableOptions[Math.floor(Math.random() * availableOptions.length)];
      const newHistory = history?.slice(0, currentStep + 1);
      const newStep = {
        options: [currentSelection, newOption],
        selected: currentSelection
      };

      setHistory([...newHistory, newStep]);
      setCurrentStep(currentStep + 1);
      setCurrentSelection(null);
      setUsedOptions(new Set([...usedOptions, newOption?.id]));
    }
  };

  const handleBackClick = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setCurrentSelection(null);
    }
  };

  return (
    <div
      className={styles.imageSelectContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      {isWelcomeScreen && (
        <div className={styles.welcomeContainer}>
          <div className={styles.imageContainer}>
            <img
              src="/images/special-capsules/your-top-challenge/orange-circle.png"
              alt="circle"
            />
            <p>У кожного з нас є свій №1. Дізнаймося твій?</p>
          </div>
          <div className={styles.buttonContainer}>
            <p>
              Перед тобою з’являтиметься пара речей. Обирай одну з них, поки не
              залишиться щось одне.
            </p>
          </div>
          <button
            className={styles.orangeButton}
            onClick={() => setIsWelcomeScreen(false)}
          >
            Далі
          </button>
        </div>
      )}
      {!isResultView && !isWelcomeScreen && (
        <>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                className={classNames(
                  styles.stepItem,
                  index === currentStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          <div style={{ position: 'relative' }}>
            <p className={styles.questionTitle}>Що обереш?</p>
            {history[currentStep].options.map((option, index) => (
              <div
                className={classNames(
                  styles.selectionItem,
                  currentSelection?.url === option?.url &&
                    styles.activeSelectionItem
                )}
                onClick={() => handleOptionClick(option)}
                key={index.toString()}
              >
                <div className={styles.imageTitle}>{option?.text}</div>
                <img src={option?.url} alt="firstSelect" />
              </div>
            ))}
            <img
              src="/images/special-capsules/your-top-challenge/or.png"
              alt="orIcon"
              className={styles.orIcon}
            />
          </div>
          <div className={styles.buttonContainer}>
            {challengeStep !== 0 && (
              <button
                className={global.reviewButton}
                onClick={() => handleBackClick()}
              >
                Назад
              </button>
            )}
            {currentSelection && (
              <button
                className={styles.orangeButton}
                onClick={handleNextClick}
                disabled={!currentSelection}
              >
                Далі
              </button>
            )}
          </div>
        </>
      )}
      {isResultView && result && (
        <div className={styles.resultView}>
          <p>Твій №1:</p>
          <p className={styles.title}>{result?.text}</p>
          <img src={result?.url} alt="resultImage" />
          <p className={styles.description}>
            Але ми знаємо, що у твоєму серці є й місце для одного бренду, що вже
            давно знає, як бути №1.
          </p>
          <button
            className={styles.orangeButton}
            onClick={() => setAfterResultScreen()}
          >
            Далі
          </button>
        </div>
      )}
    </div>
  );
};

export default YourTopSelectChallenge;
