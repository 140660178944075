export const septemberChallengeList = [
  {
    id: '1',
    title: 'На що частіше фотографуєш?',
    imageList: [
      {
        id: '1',
        url: '/images/chesterfield-6/december-challenge-2023/1.1.png'
      },
      {
        id: '2',
        url: '/images/chesterfield-6/december-challenge-2023/1.2.png'
      }
    ]
  },
  {
    id: '2',
    title: 'Який годинник тобі більше подобається?',
    imageList: [
      {
        id: '1',
        url: '/images/chesterfield-6/december-challenge-2023/2.1.png'
      },
      {
        id: '2',
        url: '/images/chesterfield-6/december-challenge-2023/2.2.png'
      }
    ]
  },
  {
    id: '3',
    title: 'Як тобі зручніше читати?',
    imageList: [
      {
        id: '1',
        url: '/images/chesterfield-6/december-challenge-2023/3.1.png'
      },
      {
        id: '2',
        url: '/images/chesterfield-6/december-challenge-2023/3.2.png'
      }
    ]
  },
  {
    id: '4',
    title: 'Яку листівку відправиш?',
    imageList: [
      {
        id: '1',
        url: '/images/chesterfield-6/december-challenge-2023/4.1.png'
      },
      {
        id: '2',
        url: '/images/chesterfield-6/december-challenge-2023/4.2.png'
      }
    ]
  },
  {
    id: '5',
    title: 'На чому більше любиш їздити?',
    imageList: [
      {
        id: '1',
        url: '/images/chesterfield-6/december-challenge-2023/5.1.png'
      },
      {
        id: '2',
        url: '/images/chesterfield-6/december-challenge-2023/5.2.png'
      }
    ]
  }
];

export const septemberResultList = [
  {
    resultId: '1',
    title: 'Фанат вічної класики',
    description:
      'Зараз тренди змінюються так швидко, що за ними неможливо встигнути. Ти віддаєш перевагу перевіреній роками класиці, що точно не підведе.'
  },
  {
    resultId: '2',
    title: 'Послідовник трендів',
    description:
      'Тренди змінюються зі швидкістю світла. Та тобі вдається за ними встигати! Ти в курсі всіх нових тенденцій та іноді навіть їх випереджаєш.'
  }
];

export const octoberChallengeList = [
  {
    title: 'Джинсовий одяг',
    description:
      "Знайомий денім оновлюється і стає стильнішим. Сьогодні джинсовий одяг набуває різних форм, кольорів та принтів. Завдяки цьому з'являється ще більше можливостей для самовираження.",
    image: '/images/chesterfield-6/october-challenge/1.png'
  },
  {
    title: 'Шкіряні куртки',
    description:
      'Сьогодні шкіряні куртки — не лише стильний елемент гардеробу. Завдяки використанню синтетичної шкіри та безпечних для довкілля матеріалів, вони стали еко-френдлі.',
    image: '/images/chesterfield-6/october-challenge/2.png'
  },
  {
    title: 'Піджаки оверсайз',
    description:
      'Піджаки оверсайз повернулися і стали ще привабливішими. Оновлені силуети, фасони та матеріали додадуть вишуканої формальності до образу.',
    image: '/images/chesterfield-6/october-challenge/3.png'
  },
  {
    title: 'Штани карго',
    description:
      'Колись ці вільні штани почали носити британські солдати. Але завдяки своїй зручності, новим кольорам та формам сьогодні вони ввійшли в мейнстрим.',
    image: '/images/chesterfield-6/october-challenge/4.png'
  },
  {
    title: 'Вельвет',
    description:
      'Сьогодні шкіряні куртки — не лише стильний елемент гардеробу. Завдяки використанню синтетичної шкіри та безпечних для довкілля матеріалів, вони стали еко-френдлі.',
    image: '/images/chesterfield-6/october-challenge/5.png'
  }
];

export const octoberResultList = {
  image: '/images/chesterfield-6/october-challenge/result.png',
  title: 'Сподіваємось, тобі було цікаво!',
  description:
    'Додай ці ідеї у свій лук та продовжуй йти в ногу з часом!\n\n P.S. Ми дуже хотіли розповісти про наш бренд, який також зробив comeback. Але юристка Юля не дозволила.'
};

export const novemberChallengeList = [
  {
    title: 'Декорації з вінілових платівок',
    image: '/images/chesterfield-6/november-challenge/9.png'
  },
  {
    title: 'Одяг у стилі тай-дай',
    image: '/images/chesterfield-6/november-challenge/8.png'
  },
  {
    title: 'Віртуальна реальність',
    image: '/images/chesterfield-6/november-challenge/7.png'
  },
  {
    title: 'Елементи ретро в дизайні',
    image: '/images/chesterfield-6/november-challenge/6.png'
  },
  {
    title: 'Комбуча',
    image: '/images/chesterfield-6/november-challenge/5.png'
  },
  {
    title: 'Йога',
    image: '/images/chesterfield-6/november-challenge/4.png'
  },
  {
    title: 'Настільні ігри',
    image: '/images/chesterfield-6/november-challenge/3.png'
  },
  {
    title: 'Елементи 80-х у одязі',
    image: '/images/chesterfield-6/november-challenge/2.png'
  },
  {
    title: 'Фольклорні мотиви в сучасній музиці',
    image: '/images/chesterfield-6/november-challenge/1.png'
  }
];

export const novemberResultList = [
  {
    title: 'Трендсеттер',
    description:
      'Нащо слідувати відомим трендам? Ти вважаєш, що значно цікавіше творити нові.'
  },
  {
    title: 'Фанат трендів',
    description:
      'Ти йдеш в ногу з часом і фанатієш від усього нового. Саме тому й намагаєшся бути в курсі останніх трендів.'
  }
];

export const decemberChallengeList = [
  {
    title: 'Крафтовий папір',
    description:
      'Використовуй крафтовий папір для пакування. Такий подарунок вразить своєю вишуканістю. Запакувати можна що завгодно: від книжок до солодощів.',
    image: '/images/chesterfield-6/december-challenge/1.png'
  },
  {
    title: 'Гравіювання',
    description:
      'Щоб зробити подарунок особливим, вигравіюй на ньому милий напис або ім’я отримувача. Так задекорувати можна прикраси чи навіть електроніку.',
    image: '/images/chesterfield-6/december-challenge/2.png'
  },
  {
    title: 'Записка від руки',
    description:
      'Напиши особливе послання від руки і вручи його з подарунком. Для близької людини це стане теплим проявом уваги та турботи.',
    image: '/images/chesterfield-6/december-challenge/3.png'
  },
  {
    title: 'Стікери',
    description:
      'Додай прикольні наліпки на подарунок. Це можуть бути цікаві написи чи жартівливі зображення, які зрозуміє лише отримувач.',
    image: '/images/chesterfield-6/december-challenge/4.png'
  },
  {
    title: 'DIY-коробка',
    description:
      'Використовуй різні цікаві та навіть неочікувані предмети для декору пакування. Прикрашання коробки власними руками додасть трохи душі до подарунку.',
    image: '/images/chesterfield-6/december-challenge/5.png'
  },
  {
    title: 'Смаколики',
    description:
      'Ідеальним доповненням до подарунка стануть улюблені смаколики. Комусь до смаку шоколадні цукерки, а хтось любить мафіни. Дізнайся про вподобання людини і даруй!',
    image: '/images/chesterfield-6/december-challenge/6.png'
  }
];

export const decemberResultList = {
  image: '/images/chesterfield-6/december-challenge/result.png',
  title: 'Сподіваємося',
  subtitle: 'тобі сподобались ідеї!',
  description:
    'Використовуй їх, і твої подарунки будуть потрапляти прямо в серденько.'
};

export const januaryChallengeList = [
  {
    id: '1',
    title: 'Який інтер’єр тобі більше до вподоби?',
    imageList: [
      {
        id: '1',
        url: '/images/chesterfield-6/january-challenge/1.1.png'
      },
      {
        id: '2',
        url: '/images/chesterfield-6/january-challenge/1.2.png'
      }
    ]
  },
  {
    id: '2',
    title: 'Обирай стильніший лук',
    imageList: [
      {
        id: '1',
        url: '/images/chesterfield-6/january-challenge/2.1.png'
      },
      {
        id: '2',
        url: '/images/chesterfield-6/january-challenge/2.2.png'
      }
    ]
  },
  {
    id: '3',
    title: 'Яка машина тобі до смаку?',
    imageList: [
      {
        id: '1',
        url: '/images/chesterfield-6/january-challenge/3.1.png'
      },
      {
        id: '2',
        url: '/images/chesterfield-6/january-challenge/3.2.png'
      }
    ]
  },
  {
    id: '4',
    title: 'У кого ефектніша зачіска?',
    imageList: [
      {
        id: '1',
        url: '/images/chesterfield-6/january-challenge/4.1.png'
      },
      {
        id: '2',
        url: '/images/chesterfield-6/january-challenge/4.2.png'
      }
    ]
  },
  {
    id: '5',
    title: 'Обери страву на сніданок',
    imageList: [
      {
        id: '1',
        url: '/images/chesterfield-6/january-challenge/5.1.png'
      },
      {
        id: '2',
        url: '/images/chesterfield-6/january-challenge/5.2.png'
      }
    ]
  }
];

export const januaryResultList = [
  {
    resultId: '1',
    title: 'Вишуканий бублик',
    description:
      'Ти не завжди любиш експерементувати, бо вважаєш, що класика завжди в тренді'
  },
  {
    resultId: '2',
    title: 'Кольорова булочка',
    description:
      'Ти не боїшся експериментувати зі стилями та прагнеш різноманіття.'
  }
];

export const februaryChallengeList = [
  {
    title: 'Лавова лампа',
    description:
      'Гіпнотичний аксесуар для кімнати знову в тренді. Тільки тепер у лавових лампах можна змінювати кольори, обирати різні форми та навіть вмикати музику!',
    image: '/images/chesterfield-6/february-challenge/1.png'
  },
  {
    title: 'Вінілові платівки',
    description:
      'Сьогодні вінілові платівки стали чудовим елементом декору. Завдяки різноманіттю кольорів та візерунків, вони можуть додати індивідуальності у будь-який простір.',
    image: '/images/chesterfield-6/february-challenge/2.png'
  },
  {
    title: 'Лампи Едісона',
    description:
      'Ці вінтажні лампочки з теплим світлом стануть гарною прикрасою для кімнати. Вони ще й заощаджують електроенергію завдяки LED-технологіям.',
    image: '/images/chesterfield-6/february-challenge/3.png'
  },
  {
    title: 'Програвач вінілу',
    description:
      'Програвачі вінілу повертаються! Окрім стильного дизайну, вони отримали нові функції: Bluetooth, USB-порти та гарну підсвітку.',
    image: '/images/chesterfield-6/february-challenge/4.png'
  },
  {
    title: 'Електрокамін',
    description:
      'Це чудове рішення, щоб створити вдома додаткове почуття затишку. Електрокаміни безпечніші, ніж звичайні, та за ними легше доглядати.',
    image: '/images/chesterfield-6/february-challenge/5.png'
  }
];

export const februaryResultList = {
  image: '/images/chesterfield-6/february-challenge/result.png',
  title: 'Як тобі ідеї?',
  subtitle: 'Використай їх для декору свого інтер’єру та будь у тренді!',
  description:
    'P.S. Ми дуже хотіли розповісти про наш бренд, який також зробив comeback. Але юристка Юля не дозволила.'
};
