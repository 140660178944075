import React from 'react';

export const challengeList = [
  {
    title: 'Впевненість',
    description: (
      <p>
        Справжнім лідерам притаманна впевненість у своїх силах і силах своєї
        команди. Вони мають сміливість приймати та відстоювати складні рішення.
      </p>
    ),
    image: '/images/marlboro/what_does_it_take_to_lead_people/1.png'
  },
  {
    title: 'Бачення',
    description: (
      <p>
        Лідери мають чітке бачення майбутнього. Вони працюють на повну, щоб
        спрямувати зусилля своєї команди на досягнення цього бачення.
      </p>
    ),
    image: '/images/marlboro/what_does_it_take_to_lead_people/2.png'
  },
  {
    title: 'Чітка комунікація',
    description: (
      <p>
        Уміння чітко та ефективно донести своє бачення та цілі — важлива
        складова лідерства. Коли команда з керівництвом «на одній хвилі», робота
        стає ефективнішою.
      </p>
    ),
    image: '/images/marlboro/what_does_it_take_to_lead_people/3.png'
  },
  {
    title: 'Адаптивність',
    description: (
      <p>
        Справжні лідери вміють адаптуватися до змін. Коли це необхідно, вони
        легко перегруповуються і досягають успіху.
      </p>
    ),
    image: '/images/marlboro/what_does_it_take_to_lead_people/4.png'
  },
  {
    title: 'Емпатія',
    description: (
      <p>
        Лідери відчувають усіх членів своєї команди. Вони прислухаються до їхніх
        потреб та реагують на занепокоєння.
      </p>
    ),
    image: '/images/marlboro/what_does_it_take_to_lead_people/5.png'
  }
];
