import React from 'react';
import HeadContainer from '../../head-container';
import ProgressBar from './ProgressBar';
import styles from './index.module.scss';

export default function ProgressBarScreen({
  setScreenNumber,
  backgroundImage,
  completed
}) {
  return (
    <div
      className={styles.ProgressBarContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentContainer}>
        <div className={styles.headContainer}>
          <p className={styles.title}>
            Перетворюємо фото на <br />
            стінопис…
          </p>
        </div>
        <div className={styles.bottomContainer}>
          <ProgressBar completed={completed} />
        </div>
      </div>
    </div>
  );
}
