import React from 'react';
import styles from './index.module.scss';

const SecondScreen = () => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>
        Загальне і комбіноване медичне попередження
      </p>
      <p className={styles.content}>
        Показуємо на прикладі нижче. Загальне медичне попередження складається з
        тексту: «Куріння вбиває - кидай курити зараз». Воно знаходиться у нижній
        частині однієї з бічних частин пачки. Комбіноване медичне попередження
        супроводжується кольоровою ілюстрацією та розміщується на одиничних
        пачках та зовнішньому упакуванні тютюнових виробів для куріння.
      </p>
      <img src="/images/ghws/second.png" alt="first" className={styles.image} />
      <p className={styles.content}>
        Інформаційне повідомлення: «Тютюновий дим містить більше 70 речовин,
        здатних викликати захворювання на рак», знаходиться в нижній частині
        іншої боковини пачки.
      </p>
    </div>
  );
};

export default SecondScreen;
