import React, { useCallback, useContext } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../components/lm-d-s/head-container';
import classNames from 'classnames';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';
import useTelegramLink from '../../../hooks/useTelegramLink';

const FinalScreen = ({
  setScreenNumber,
  setScreenNumberToReview,
  handleBackButton = () => setScreenNumber(1),
  withReviewButton = false
}) => {
  const { sendEvent } = useContext(DataLayerEventContext);
  const { telegramLink } = useTelegramLink();

  const onTelegramClick = useCallback(() => {
    window.open(telegramLink, '_blank');
    sendEvent('telegram');
  }, [sendEvent, telegramLink]);

  const onLeaveFeedbackClick = useCallback(() => {
    if (setScreenNumberToReview) {
      setScreenNumberToReview();
    } else {
      setScreenNumber(12);
    }
    sendEvent('review');
  }, [sendEvent, setScreenNumber, setScreenNumberToReview]);

  return (
    <div className={styles.finalScreenContainer}>
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentContainer}>
        <div>
          <div className={styles.titleContainer}>
            <p>Дякую,</p>
            <p>що обираєш продукцію Філіп Морріс Україна.</p>
          </div>
        </div>
        <div className={styles.telegramContainer}>
          <p>
            Долучайся до нас у телеграмі, щоб бути на зв’язку зі своїм брендом.
          </p>
          <img
            src="/images/telegram.png"
            alt="telegram-icon"
            onClick={onTelegramClick}
          />
        </div>
        <div
          className={classNames(
            styles.buttonContainer,
            !withReviewButton && styles.buttonContainerWithMargin
          )}
        >
          <button className={styles.menuButton} onClick={handleBackButton}>
            Повернутися на початок
          </button>
          {withReviewButton && (
            <button
              className={styles.reviewButton}
              style={{ marginTop: 10 }}
              onClick={onLeaveFeedbackClick}
            >
              Залишити відгук
            </button>
          )}
          <div className={styles.infoContainer}>
            <p>Контент регулярно оновлюється</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalScreen;
