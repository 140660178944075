import React, { useState, useMemo } from 'react';
import HeadContainer from '../../head-container';
import styles from './index.module.scss';
import classNames from 'classnames';

export default function DownloadPhotoScreen({
  setScreenNumber,
  backgroundImage,
  setStep
}) {
  const handleClick = () => {
    setStep((prev) => prev + 1);
  };

  return (
    <div
      className={styles.DownloadPhotoContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentContainer}>
        <div className={styles.headContainer}>
          <p className={styles.title}>
            Додай <br />
            світлину
          </p>
        </div>
        <div className={styles.bottomContainer}>
          <button
            className={classNames(styles.muralButton, global.menuButton)}
            onClick={handleClick}
          >
            <span>Зробити фото</span>
            <div>
              <img
                src="/images/mural/btn_icon.svg"
                alt="button"
                className={styles.buttonIcon}
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
