import React, { useState } from 'react';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../common/menu-screen';
import WarningFooter from '../common/warning-footer/WarningFooter';
import AuthorityScreen from '../common/authority-screen';
import styles from '../components/lm-d-s/index.module.scss';
import InfoScreen from '../common/info-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../common/characteristic-screen';
import ReviewScreen from '../common/review-screen';
import { useContext } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import { useEffect } from 'react';
import WelcomeChallengeScreen from "../common/challenge-component/welcome-challenge-screen";
import FinalScreen from "../common/final-screen";
import CommerceVideoScreen from "../components/commerce-video-screen/CommerceVideoScreen";
import characteristicStyles from '../common/characteristic-screen/index.module.scss'
import SelectSeasonChallenge from "../common/challenge-component/select-season-challenge";
import ResultInfoScreen from "../common/challenge-component/result-info-screen";
import AdviceChallenge from "../common/challenge-component/advice-challenge";
import {fiveSohoFactsChallengeList} from "../consts/soho-facts-challenge";
import InstantQuizChallenge from "../common/challenge-component/instant-quiz-challenge";
import QuizChallenge from "../common/challenge-component/quiz-challenge";

const PROJECT_NAME = 'Parliament soho compact blue';

const filterCharacteristicList = [
    {
        description: (
            <>
                <span className={characteristicStyles.filterInfoBlock}>
                    Ацетатна частина
                </span>
                <span className={characteristicStyles.filterInfoBlock}>
                    Вугільна частина
                </span>
                <span className={characteristicStyles.filterInfoBlock}>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким і більш збалансованим
                </span>
            </>
        ),
        bordered: false
    }
];

const characteristicInfoList = [
    {
        title: 'Формат ',
        description: '- King Size Slim (Кінг сайз слім)'
    },
    {
        title: 'Кількість сигарет у пачці ',
        description: '– 20'
    },
    {
        title: 'Кількість пачок у блоці ',
        description: '– 10'
    }
];

const menu = [
    {
        id: 'challenge',
        screenNumber: 9,
        name: 'Челендж 1',
        icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
    },
    {
        id: 'challenge-2',
        screenNumber: 6,
        name: 'Челендж 2',
        icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
    },
    {
        id: 'challenge-3',
        screenNumber: 12,
        name: 'Челендж 3',
        icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
    },
    {
        id: 'characteristic',
        screenNumber: 4,
        name: 'Характеристики сигарет',
        icon: 'images/lm-double-splash/characteristic-menu-icon.svg'
    },
    {
        id: 'info',
        screenNumber: 3,
        name: 'Все про Філіп Морріс Україна',
        icon: 'images/lm-double-splash/info-menu-icon.svg'
    },
    {
        id: 'authority',
        screenNumber: 2,
        name: 'Як відрізнити підробку від оригіналу?',
        icon: 'images/lm-double-splash/authority-menu-icon.svg'
    },
    {
        id: 'review',
        screenNumber: 17,
        name: 'Залишити відгук про продукт',
        icon: 'images/lm-double-splash/review-menu-icon.svg'
    }
];

const PRL_S_6 = () => {
    const [screenNumber, setScreenNumber] = useState(1);
    const [endSessionType, setEndSessionType] = useState('SESSION_END');
    const [isDisplayedAll, setIsDisplayedAll] = useState(false);

    const { init } = useContext(DataLayerEventContext);

    useEffect(() => {
        init(PROJECT_NAME);
    }, [init]);

    const getScreen = (number, cb) => {
        switch (number) {
            case 1: {
                return (
                    <MenuScreen
                        setScreenNumber={cb}
                        menuList={menu}
                        backgroundImage={`url("/images/parliament/bg.png")`}
                    />
                );
            }
            case 2: {
                return <AuthorityScreen setScreenNumber={cb} />;
            }
            case 3: {
                return <InfoScreen setScreenNumber={cb} />;
            }
            case 4: {
                return (
                    <CharacteristicScreen
                        title="PARLIAMENT SOHO COMPACT BLUE"
                        setScreenNumber={setScreenNumber}
                        filterCharacteristicList={filterCharacteristicList}
                        infoList={characteristicInfoList}
                        tobaccoFirst="Вміст смол, мг - 6"
                        tobaccoSecond="Вміст нікотину, мг - 0.5"
                        packImage="/images/parliament/prl-s-6.png"
                        handleBackButton={() => setScreenNumber(1)}
                        handleNextButton={() => setScreenNumber(10)}
                        backgroundImage="/images/parliament/bg.png"
                        filterIcon="/images/parliament/filter.png"
                    />
                );
            }
            case 5: {
                return (
                    <CommerceVideoScreen
                        videoSrc="/videos/soho-facts.mp4"
                        setScreenNumber={cb}
                        nextScreenNumber={6}
                        changeScreenImmediately
                        skipScreenImmediately
                        controls={false}
                        buttonStyle="skip-video"
                        hasButtonArrow={true}
                    />
                )
            }
            case 6: {
                return (
                    <WelcomeChallengeScreen
                        setScreenNumber={setScreenNumber}
                        backgroundImage={`url("/images/parliament/bg.png")`}
                        title={(<>А до яких кіл<br></br>у Сохо належиш ти?</>)}
                        description={(<>Район Сохо в Нью-Йорку притягує до себе багато творчих особистостей.<br></br>Пройди тест, щоб дізнатися.</>)}
                        buttonTitle="Почати"
                        setNextScreenNumber={() => setScreenNumber(7)}
                    />
                );
            }
            case 7: {
                return (
                    <SelectSeasonChallenge
                        backgroundImage={`url("/images/parliament/bg.png")`}
                        setScreenNumber={setScreenNumber}
                        setNextScreenNumber={() => setScreenNumber(8)}
                        style="soho"
                        type="soho"
                    />
                )
            }
            case 8: {
                return (
                    <ResultInfoScreen
                        setScreenNumber={setScreenNumber}
                        setNextScreenNumber={() => setScreenNumber(15)}
                        title={'Задоволений результатом?'}
                        description={(<>Ким би ти не був, у Сохо<br></br>знайдеться місце для кожного!</>)}
                        image="/images/select-season-challenge/soho/finish.svg"
                        backgroundImage={'url("/images/parliament/bg.png")'}
                    />
                )
            }
            case 9: {
                return (
                    <WelcomeChallengeScreen
                        setScreenNumber={setScreenNumber}
                        backgroundImage={`url("/images/parliament/bg.png")`}
                        title={(<>5 фактів<br></br>про Сохо</>)}
                        description="Від унікальної архітектури до творчих оаз — район Сохо в Нью-Йорку манить туристів своєю атмосферою. Ми підготували 5 фактів про Сохо, щоб познайомити тебе ближче з яскравим районом."
                        buttonTitle="Почати"
                        setNextScreenNumber={() => setScreenNumber(10)}
                    />
                );
            }
            case 10: {
                return (
                    <AdviceChallenge
                        setScreenNumber={cb}
                        challengeList={fiveSohoFactsChallengeList}
                        backgroundImage={`url("/images/parliament/bg.png")`}
                        setScreenNumberAfterResult={() => setScreenNumber(11)}
                        withCounter={false}
                    />
                )
            }
            case 11: {
                return (
                    <InstantQuizChallenge
                        setScreenNumber={setScreenNumber}
                        type="soho-facts"
                        backgroundImage={`url("/images/parliament/bg.png")`}
                        setScreenNumberAfterResult={() => setScreenNumber(15)}
                    />
                )
            }
            case 12: {
                return (
                    <QuizChallenge
                        setScreenNumber={() => setScreenNumber(15)}
                        type="musical-soho-playlist-to-suit-your-mood"
                        backgroundImage={`url("/images/parliament/bg.png")`}
                        resultTitleFontSize={48}
                        hasListMarkers={false}
                        hasResultPrev={true}
                        hasResultNav={false}
                        style={'sohoPlaylist'}
                    />
                )
            }
            case 15: {
                return <FinalScreen setScreenNumber={cb} withReviewButton />;
            }
            case 16: {
                return <FinalScreen setScreenNumber={cb} />;
            }
            case 17: {
                return (
                    <ReviewScreen
                        setScreenNumber={cb}
                        setSubmitReviewScreenNumber={() => setScreenNumber(11)}
                        SHEET_ID="1641227143"
                    />
                );
            }
            default: {
                return <ErrorScreen setScreenNumber={cb} />;
            }
        }
    };

    return (
        <div className={styles.pageContainer} id="lm-screen-container">
            <div className={styles.contentContainer}>
                <ScreensContext.Provider
                    value={{
                        endSessionType,
                        setEndSessionType,
                        isDisplayedAll,
                        setIsDisplayedAll
                    }}
                >
                    {getScreen(screenNumber, setScreenNumber)}
                </ScreensContext.Provider>
            </div>
            <WarningFooter screenNumber={screenNumber} />
        </div>
    );
};

export default PRL_S_6;
