import React, { useCallback, useContext } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';
import useTelegramLink from '../../../hooks/useTelegramLink';

const HeadContainer = ({ setScreenNumber, isDarkMode = false }) => {
  const { telegramLink } = useTelegramLink();
  const { sendEvent } = useContext(DataLayerEventContext);

  const onTelegramClick = useCallback(() => {
    window.open(telegramLink, '_blank');
    sendEvent('telegram');
  }, [sendEvent, telegramLink]);

  return (
    <div className={styles.headContainer}>
      <svg
        width="38"
        height="20"
        viewBox="0 0 38 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => setScreenNumber(1)}
      >
        <rect
          width="38"
          height="4"
          rx="2"
          fill={isDarkMode ? '#0C3675' : 'white'}
        />
        <rect
          y="16"
          width="38"
          height="4"
          rx="2"
          fill={isDarkMode ? '#0C3675' : 'white'}
        />
        <rect
          y="8"
          width="18"
          height="4"
          rx="2"
          fill={isDarkMode ? '#0C3675' : 'white'}
        />
      </svg>
      <div>
        <span
          className={classNames(isDarkMode && styles.darkText)}
          onClick={onTelegramClick}
        >
          Є питання?
        </span>
        <svg
          width="27"
          height="21"
          viewBox="0 0 27 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onTelegramClick}
        >
          <path
            d="M1.86207 0C0.837931 0 0 0.821739 0 1.82609V16.4348L2.7931 13.6957H20.4828C21.5069 13.6957 22.3448 12.8739 22.3448 11.8696V1.82609C22.3448 0.821739 21.5069 0 20.4828 0H1.86207ZM23.2759 7.30435V11.8696C23.2759 13.4217 22.0655 14.6087 20.4828 14.6087H13.0345V16.4348C13.0345 17.4391 13.8724 18.2609 14.8966 18.2609H24.2069L27 21V9.13043C27 8.12609 26.1621 7.30435 25.1379 7.30435H23.2759Z"
            fill={isDarkMode ? '#0C3675' : 'white'}
          />
        </svg>
      </div>
      {/*{isOpenChatModal && (*/}
      {/*  <div className={styles.modal}>*/}
      {/*    <div*/}
      {/*      className={styles.background}*/}
      {/*      onClick={() => setIsOpenChatModal(false)}*/}
      {/*    />*/}
      {/*    <div className={styles.modalContent}>*/}
      {/*      <div*/}
      {/*        className={styles.closeContainer}*/}
      {/*        onClick={() => setIsOpenChatModal(false)}*/}
      {/*      >*/}
      {/*        <CloseIcon />*/}
      {/*      </div>*/}
      {/*      <p>Задай своє питання представнику бренду</p>*/}
      {/*      <div className={styles.linkContainer}>*/}
      {/*        <img*/}
      {/*          src="images/telegram.png"*/}
      {/*          alt="telegram-icon"*/}
      {/*          onClick={onTelegramClick}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default HeadContainer;
