import React from 'react';
import styles from '../components/lm-d-s/index.module.scss';
import WarningFooter from '../common/warning-footer/WarningFooter';

const PersonalDataPolicy = () => {
  return (
    <div className={styles.pageContainer} id="ghws-screen-container">
      <div
        className={styles.contentContainer}
        style={{
          backgroundImage: `url("/images/lm-double-splash/violet-background.png")`,
          backgroundSize: 'cover'
        }}
      >
        <div className={styles.personalDataPolicy}>
          <p className={styles.title}>
            Положення про конфіденційність та захист персональних даних
          </p>
          <p style={{ marginTop: '43px' }} className={styles.content}>
            від 08 квітня 2024 року
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            Приймаючи це Положення про конфіденційність, Ви підтверджуєте, що Ви
            старше 18 років, проживаєте в Україні і є курцем, безумовно
            розумієте і погоджуєтеся із зазначеними нижче умовами і цілями
            використання Ваших персональних даних, надаєте згоду на обробку
            Ваших персональних даних Товариству з обмеженою відповідальністю
            «Філіп Морріс Сейлз енд Дистриб’юшн», , а також безумовно розумієте
            і погоджуєтеся, що таке використання Ваших персональних даних не є
            підставою для виплати Вам будь-якої винагороди або компенсації. ТОВ
            «ФМСД» обробляє Ваші персональні дані у обсязі пропорційному до мети
            їх обробки.  Крім того, Ви надаєте згоду на обробку Ваших
            персональних даних материнській компанії ТОВ «ФМСД» — Філіп Морріс
            Інтернешнл Інк. (ФМІ) — згідно з Повідомленням ФМІ про приватність
            споживачів.{' '}
            <a href="https://www.pmiprivacy.com/ukraine/uk/consumer/">
              Повідомлення ФМІ про приватність споживачів.
            </a>
          </p>
          <p style={{ marginTop: '50px' }} className={styles.content}>
            ТОВ «ФМСД» поважає таємницю приватного життя своїх споживачів. ТОВ
            «ФМСД» зобов'язується вжити відповідних заходів щодо безпеки
            персональних даних, які Ви надаєте. Дане Положення про
            конфіденційність описує всі види персональних даних, які ТОВ «ФМСД»
            отримує від вас, а також те, як ТОВ «ФМСД» їх використовує і кому їх
            надає. Дане Положення про конфіденційність також описує ті заходи,
            яких ТОВ «ФМСД» вживає для забезпечення безпеки інформації. 
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            ТОВ «ФМСД» залишає за собою право у будь-який час вносити зміни до
            цього Положення про конфіденційність. ТОВ «ФМСД» буде повідомляти
            Вас про внесення будь-яких суттєвих змін до цього Положення про
            конфіденційність. Якщо Ви не згодні з цим Положенням про
            конфіденційність та будь-якими суттєвими змінами, що можуть бути
            внесені у майбутньому, будь ласка, не надавайте таку згоду.
          </p>
          <p
            style={{ marginTop: '50px', textAlign: 'left', width: '90%' }}
            className={styles.title}
          >
            1. Згода на обробку Ваших персональних даних
          </p>
          <p style={{ marginTop: '12px' }} className={styles.content}>
            Прийняття даного Положення про конфіденційності означає надання Вами
            згоди на обробку Ваших персональних даних згідно з цим Положенням
            про конфіденційність та Законом України «Про захист персональних
            даних». Ви підтверджуєте, що всі наведені Вами персональні дані є
            достовірними та точними. Ви повністю усвідомлюєте, що несете
            відповідальність за надання ТОВ «ФМСД» недостовірних персональних
            даних про себе.
          </p>
          <p style={{ marginTop: '12px' }} className={styles.content}>
            Ви підтверджуєте, що добровільно та безоплатно надаєте ТОВ «ФМСД»
            свою згоду на будь-яку обробку Ваших персональних даних (в т.ч.
            даних телекомунікаційної поведінки) на строк не довше, ніж це
            необхідно для досягнення нижчезазначеної мети , включаючи:
          </p>
          <ul className={styles.list}>
            <li>прізвище, ім’я та по батькові,</li>
            <li>дату народження,</li>
            <li>номер стаціонарного або/та мобільного телефону,</li>
            <li>адресу електронної пошти,</li>
            <li>cookies веб-сайту,</li>
            <li>IP-адресу,</li>
            <li>інформацію про браузер, зокрема тип та версію браузера,</li>
            <li>інформацію про пристрій, який ви використовуєте,</li>
            <li>
              інформацію про споживчі переваги певних марок сигарет і інших
              тютюнових виробів, які згоряють при споживанні,
            </li>
            <li>
              надання інформації про зазначену продукцію на запит повнолітніх
              курців,
            </li>
            <li>інші, які будуть окремо запитані та/або зібрані від Вас,</li>
          </ul>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            у базі даних повнолітніх курців. Під обробкою розуміється в тому
            числі збирання, реєстрація, включення в бази даних, накопичення,
            зберігання, адаптування, зміна, поновлення, використання, поширення
            (розповсюдження, реалізація, передача, надання доступу будь-яким
            третім особам, включаючи нерезидентів (іноземних суб’єктів
            відносин), без будь-якого окремого повідомлення Вас, про здійснення
            такого розповсюдження, реалізації, передачі, або надання доступу),
            знеособлення, знищення персональних даних у базі персональних даних,
            як на території України, так і за кордоном, з метою:
          </p>
          <ul className={styles.numberList}>
            <li>
              з’ясування споживчих переваг курців щодо марок сигарет і інших
              тютюнових виробів, які згоряють при споживанні,
            </li>
            <li>
              надання актуальної і достовірної інформацію про сигаретну
              продукцію і іншу тютюнову продукцію, яка згорає при споживанні
              (включаючи текстові матеріали та зображення Продукції), в тому
              числі засобами дистанційного зв’язку, пристосування для її
              використання або вживання, а також про розташування місць продажу,
              у відповідності з українським законодавством про захист прав
              споживачів,
            </li>
            <li>
              аналіз обсягу взаємодії повнолітнього споживача з контентом
              Компанії в її каналах взаємодії з повнолітніми курцями,
            </li>
            <li>та іншою метою, яка може бути окремо визначена окремо.</li>
          </ul>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            Крім того, Ви надаєте згоду ТОВ «ФМСД» на обробку Ваших персональних
            даних із зазначеною метою, якщо такі персональні дані були передані
            ТОВ «ФМСД» іншими особами за умови отримання такими особами Вашої
            згоди та дотримання інших вимог законодавства України про захист
            персональних даних.
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            Ви також цим надаєте згоду на надання будь-яким розпорядникам і
            третім особам, включаючи нерезидентам (іноземним суб’єктам відносин)
            та постачальникам електронних (теле)комунікаційних послуг чи
            пов’язаним з такою діяльністю компаніям, право обробляти Ваші
            персональні дані в рамках зазначеної мети.
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            Ви також підтверджуєте, що добровільно надаєте ТОВ «ФМСД», його
            філіям, афілійованим особам та уповноваженим ними особам свою згоду
            направляти Вам на вказані Вами адресу електронної пошти та номери
            телефонів інформацію згідно мети обробки Ваших персональних даних.
            Ви погоджуєтеся негайно інформувати ТОВ «ФМСД» про зміну Ваших
            персональних даних та (або) зміну статусу курця. ТОВ «ФМСД»
            здійснюватиме заходи щодо зниження обсягу обробки даних виключно до
            тих, які необхідні для визначених цілей обробки. Ця згода діє до
            дати її відкликання Вами шляхом направлення на зазначену нижче
            поштову адресу «ФМСД» письмового повідомлення.
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            Повідомляємо Вас, що володільцем Ваших персональних даних є ТОВ
            «ФМСД», місцезнаходження: Україна, 04070, м. Київ, вул. Спаська, 30.
            Надання згоди на обробку персональних даних відповідно до даного
            Положення про конфіденційність означає надання згоди на збирання
            наступних персональних даних: прізвище, ім’я, по батькові, дата
            народження, ІПН, місце (адреса) проживання (реєстрації), номер
            стаціонарного та/або мобільного телефону, адреса електронної пошти,
            стать тощо. Як суб’єкт персональних даних Ви маєте право:
          </p>
          <ul className={styles.numberList}>
            <li>
              знати про джерела збирання, місцезнаходження своїх персональних
              даних, мету їх обробки, місцезнаходження або місце проживання
              (перебування) володільця чи розпорядника персональних даних або
              дати відповідне доручення щодо отримання цієї інформації
              уповноваженим ним особам, крім випадків, встановлених законом;
            </li>
            <li>
              отримувати інформацію про умови надання доступу до персональних
              даних, зокрема інформацію про третіх осіб, яким передаються його
              персональні дані;
            </li>
            <li>на доступ до своїх персональних даних;</li>
            <li>
              отримувати не пізніш як за тридцять календарних днів з дня
              надходження запиту, крім випадків, передбачених законом, відповідь
              про те, чи обробляються його персональні дані, а також отримувати
              зміст таких персональних даних;
            </li>
            <li>
              пред'являти вмотивовану вимогу володільцю персональних даних із
              запереченням проти обробки своїх персональних даних;
            </li>
            <li>
              пред'являти вмотивовану вимогу щодо зміни або знищення своїх
              персональних даних будь-яким володільцем та розпорядником
              персональних даних, якщо ці дані обробляються незаконно чи є
              недостовірними;
            </li>
            <li>
              на захист своїх персональних даних від незаконної обробки та
              випадкової втрати, знищення, пошкодження у зв'язку з умисним
              приховуванням, ненаданням чи несвоєчасним їх наданням, а також на
              захист від надання відомостей, що є недостовірними чи ганьблять
              честь, гідність та ділову репутацію фізичної особи;
            </li>
            <li>
              звертатися із скаргами на обробку своїх персональних даних до
              Уповноваженого Верховної Ради України з прав людини або до суду;
            </li>
            <li>
              застосовувати засоби правового захисту в разі порушення
              законодавства про захист персональних даних;
            </li>
            <li>
              вносити застереження стосовно обмеження права на обробку своїх
              персональних даних під час надання згоди;
            </li>
            <li>відкликати згоду на обробку персональних даних;</li>
            <li>знати механізм автоматичної обробки персональних даних;</li>
            <li>
              на захист від автоматизованого рішення, яке має для нього правові
              наслідки.
            </li>
          </ul>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            З усіх питань щодо обробки ТОВ «ФМСД» Ваших персональних даних Ви
            можете звертатися за адресою: ТОВ «ФМСД», вул. Спаська, 30, м. Київ,
            04070, або за номером телефону 4767, або електронною поштою:
            support.customer@pmi.com
          </p>
          <p
            style={{ marginTop: '50px', textAlign: 'left', width: '90%' }}
            className={styles.title}
          >
            2. Як ТОВ «ФМСД» захищає персональні дані
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            Безпека персональних даних є для ТОВ «ФМСД» пріоритетом. ТОВ «ФМСД»
            дотримується і забезпечує, щоб допущені треті особи дотримувалися
            відповідних адміністративних, технічних і фізичних заходів щодо
            забезпечення безпеки, розроблених для захисту від несанкціонованого
            розкриття, використання, модифікації або знищення персональних
            даних, які Ви надаєте. ТОВ «ФМСД» використовує захищені канали
            зв'язку для забезпечення безпеки передачі Ваших персональних даних.
          </p>
          <p
            style={{ marginTop: '50px', textAlign: 'left', width: '90%' }}
            className={styles.title}
          >
            3. Як ТОВ «ФМСД» використовує персональні дані
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            Коли Ви відвідуєте Сайт, Ваш браузер автоматично направляє ТОВ
            «ФМСД» адреси інтернет-протоколу (IP) і деяку іншу інформацію (таку
            як тип браузера, який Ви використовуєте). ТОВ «ФМСД» не використовує
            клієнтські маркери для збереження Ваших персональних даних та
            інформації про доступ.
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            Повідомлення про конфіденційність інструментів аналітики
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            ТОВ «ФМСД» може використовувати технології відстеження сеансів,
            надані нам Google Analytics. Ця технологія працює на наших серверах,
            і зібрані дані залишаються у нашому розпорядженні та не передаються
            третім особам. Така технологія може збирати інформацію від кінцевих
            користувачів («Кінцевий користувач») різних онлайн-сервісів
            («Сервіс»). Він може включати дані про трафік, такі як сервер
            домену, тип веб-браузера, геолокацію, перегляд та активність потоку
            кліків, теплові карти, прокручування та багато іншого.
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            При включенні мобільної версії ця технологія може надавати нам
            інформацію про тип мобільного пристрою, який ви використовуєте,
            роздільну здатність екрану вашого мобільного пристрою, тип вашого
            мережевого підключення, вашу мобільну операційну систему, інші
            діагностичні дані на основі вашої взаємодії з Сайтом (дотику, взмаху
            тощо) та обмежені знімки екрану Сайту, які виключають всю видиму
            особисту інформацію.
          </p>
          <p style={{ marginTop: '15px' }} className={styles.content}>
            Сервіс також призначений для автоматичного збору інформації про
            різні відвідування вами веб-сайту та використання програм — сторінок
            переходу та виходу, URL-адрес, кількість часу, проведеного на певних
            сторінках, розділах / функціях веб-сайтів / програм, які були
            відвідані / використовувалися.
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            Ми розробили наш Сервіс таким чином, щоб інформація, отримана від
            кінцевих користувачів, була анонімною і не дозволяла ідентифікувати
            його/її/їх особисто, але при цьому могла бути корисною для
            покращення взаємодії кінцевих користувачів з Сайтом.
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            Щоб зв'язатися з ТОВ «ФМСД» щодо будь-чого з вищевикладеного, або
            якщо ви хочете відмовитися від наших аналітичних послуг, напишіть
            нам за адресою support.customer@pmi.com
          </p>
          <p
            style={{ marginTop: '50px', textAlign: 'left', width: '90%' }}
            className={styles.title}
          >
            4. Інформація, яку ТОВ «ФМСД» надає
          </p>
          <p style={{ marginTop: '8px' }} className={styles.content}>
            ТОВ «ФМСД» не передає і не розкриває надані Вами персональні
            даніяким-небудь третім особам для їх власних маркетингових цілей.
            Надані Вами персональні даніможуть бути розкриті будь-якій з
            Допущених третіх осіб і в межах, не заборонених чинним
            законодавством, можуть бути передані в іншу країну, в якій ми і /
            або будь-яка з Допущених третіх осіб здійснює діяльність, включаючи,
            але не обмежуючись, імпорт Ваших персональних даних. У такому разі
            Ви цим надаєте однозначну згоду на передачу Ваших персональних даних
            будь-якій з Допущених третіх осіб з метою обробки персональних даних
            згідно з цим Положенням про конфіденційність, в тому числі до країн,
            які не визнані такими, що забезпечують належний захист персональних
            даних. ТОВ «ФМСД» і Допущені треті особи можуть також розкривати
            Ваші персональні дані, якщо це вимагається згідно із законодавством,
            що застосовується. Шляхом включення Вас в нашу базу даних
            повнолітніх споживачів Продукції Ви безумовно розумієте і
            погоджуєтеся з вищеперерахованими умовами розкриття та передачі
            Ваших персональних даних.
          </p>
          <p
            style={{ marginTop: '50px', textAlign: 'left', width: '90%' }}
            className={styles.title}
          >
            5. Відписка від отримання інформації від ТОВ «ФМСД»
          </p>
          <p
            style={{ marginTop: '8px', paddingBottom: '57px' }}
            className={styles.content}
          >
            Щоб відписатись тільки від телеграму «Бот Філіп» треба покинути
            групу в Телеграмі Для повної відписки від комунікації потрібно
            відписатись через посилання яке міститься в кінці кожної SMS
            комунікації
          </p>
          <div className={styles.footerContainer}>
            <span>Дана інформація про продукт надається</span>
            <span>відповідно до статті 15 Закону України</span>
            <span>«Про захист прав споживачів»</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDataPolicy;
