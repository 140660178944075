import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../head-container';
import WarningFooter from '../warning-footer/WarningFooter';

const AuthorityScreen = ({
  setScreenNumber,
  setReviewScreenNumber,
  partners
}) => {
  return (
    <div className={styles.authorityContainer}>
      <div className={styles.content}>
        <HeadContainer setScreenNumber={setScreenNumber} />
        <span className={styles.title}>
          Як відрізнити оригінальні сигарети від підробки?
        </span>
        <span className={styles.description} style={{ marginTop: 26 }}>
          Існує низка прийомів, які допоможуть відрізнити оригінал від підробки.
          Розглянемо детально кожен з них.
        </span>
        <div className={styles.blockContainer} style={{ marginTop: 32 }}>
          <span className={styles.blockTitle}>Акцизна марка</span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Наявність української акцизної марки під плівкою пачки. Акцизна
            марка без голограми, надрукована на тонкому папері, а фарба на ній
            нанесена нечітко та розмито – це очевидний сигнал, що перед вами
            підробка. Кожна акцизна марка має унікальний номер. Якщо помітите,
            що на кількох пачках наклеєно «акцизку» з однаковим номером – це
            підробка.
          </span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Також згідно постанови № 618 від 19 червня 2023 року, з 1 вересня
            2023 року в Україні будуть запроваджені марки акцизного податку на
            алкогольні напої та тютюнові вироби нового зразка з додатковим
            захисним голографічним елементом. Це означає, що всі сигарети
            виготовлені з 1 вересня, мають мати акцизну марку нового зразка.
            Проте слід зазначити, що кожен виробник має на своїх складах певний
            запас продукції та акцизних марок старого зразка (кольору), а отже
            така продукція ще буде потрапляти у торгові точки для реалізації.
            Нижче розглянемо, які основні зміни сталися в акцизних марках і які
            акцизні марки можуть бути на офіційних продуктах категорії сигарети.
          </span>
          <div className={styles.imagesContainer}>
            <p className={styles.imagesContainerTitle}>
              Акцизна марка для сигарет, <br />
              вироблених в Україні
            </p>
            <div className={styles.stampsContainer}>
              <img src="/images/lm-double-splash/old_1.png" alt="pack-image" />
              <span>стара</span>
            </div>
            <div className={styles.stampsContainer}>
              <img src="/images/lm-double-splash/new_1.png" alt="pack-image" />
              <span>нова</span>
            </div>
          </div>
          <div className={styles.imagesContainer}>
            <p className={styles.imagesContainerTitle}>
              Акцизна марка для імпортованих <br />
              сигарет
            </p>
            <div className={styles.stampsContainer}>
              <img src="/images/lm-double-splash/old_2.png" alt="pack-image" />
              <span>стара</span>
            </div>
            <div className={styles.stampsContainer}>
              <img src="/images/lm-double-splash/new_2.png" alt="pack-image" />
              <span>нова</span>
            </div>
          </div>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Ще раз підкреслюємо, що пачки з акцизними марками старого зразка
            також дозволені до продажу. Це означає, що під час перехідного
            періоду на нові акцизні марки в одній точці продажу може бути один
            продукт з різними акцизними марками.
          </span>
        </div>
        <div className={styles.blockContainer} style={{ marginTop: 40 }}>
          <span className={styles.blockTitle}>Пачка</span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Фарба на підроблених пачках найчастіше нанесена нечітко, іноді може
            жовтити або віддавати іншими відтінками, що відрізняються від
            кольору оригіналу. Тексти на пачках можуть бути нечіткими та
            розмитими, фарба може обсипатися через неякісний друк. У текстах
            можуть бути помилки та опечатки.
          </span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Кожна оригінальна пачка має бути упакована в якісне целофанове
            пакування, яке щільно прилягає.
          </span>
        </div>
        <div className={styles.blockContainer} style={{ marginTop: 50 }}>
          <span className={styles.blockTitle}>Ціна</span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Сигарети, які коштують підозріло дешево – це вже привід
            насторожитися та уважніше придивитися до пачки. Відповідно до вимог
            законодавства України, на кожній пачці сигарет має бути надрукована
            максимальна роздрібна ціна сигарет (МРЦ), а також дата виробництва
            кожної пачки сигарет. Цю інформацію можна знайти на дні пачки, вона
            зазвичай надрукована на чорному тлі.
          </span>
          <div className={styles.firstPriceContainer}>
            <img
              src="/images/lm-double-splash/authority_price_new_1.png"
              alt="first-price"
            />
          </div>
          <span className={styles.imageTitle}>
            Надрукована максимальна роздрібна ціна сигарет
          </span>
          <div
            className={styles.secondPriceContainer}
            style={{ justifyContent: 'center' }}
          >
            <img
              src="/images/lm-double-splash/authority_price_new_2.png"
              alt="second-price"
            />
          </div>
          <span className={styles.imageTitle}>
            Дата виробництва кожної пачки сигарет
          </span>
        </div>
        <div className={styles.blockContainer} style={{ marginTop: 40 }}>
          <span className={styles.blockTitle}>Вміст пачки. Сигарети</span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Сигарети офіційних виробників охайно і щільно вкладені в пачку,
            завжди фільтром догори. Фарба та надписи на фільтрі повинні бути
            чіткими і без помилок. Усередині оригінальної упаковки завжди є
            захисна фольга або папір, який «закриває» сигарети. Тютюн в
            оригінальних сигаретах завжди вкладений щільно.
          </span>
        </div>
        <div className={styles.blockContainer} style={{ marginTop: 43 }}>
          <span className={styles.blockTitle}>Медичне попередження</span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Відповідно до законодавства України на всіх тютюнових виробах має
            бути надруковано медичне попередження щодо впливу тютюнових виробів
            на здоров’я споживача.
            <br />
            <br />
            11 січня 2024 року, набули чинності зміни до статті 12 Закону
            України «Про заходи щодо попередження та зменшення вживання
            тютюнових виробів і їх шкідливого впливу на здоров’я населення», що
            стосуються медичних попереджень споживачів тютюнових виробів для
            куріння.
            <br />
            <br />
            Тепер на кожній пачці сигарет зазначаються 2 попередження - загальне
            та комбіноване, що супроводжується відповідною кольоровою
            ілюстрацією, а також, розміщується текстове повідомлення. Таке
            повідомлення має бути надруковано українською мовою.
            <br />
            <br />
            Медичні попередження на пачках сигарет будуть містити картинку з
            обох сторін упаковки, а також будуть збільшені з 50% до 65% від
            розміру упаковки:
          </span>
          <div className={styles.warningImage}>
            <img
              src="/images/lm-double-splash/medical-warning-image.png"
              alt="warning-image"
            />
          </div>
          <span className={styles.imageTitle}>Основне попередження</span>
          <span className={styles.partners} style={{ marginTop: 20 }}>
            Офіційні сигарети можна придбати онлайн у офіційних партнерів
            <div className={styles.partnersButtons}>
              <button className={styles.link}>
                <a href={partners ? partners.cig : ''}>Cig-Poshta</a>
              </button>
              <button className={styles.link}>
                <a href={partners ? partners.rozetka : ''}>Rozetka</a>
              </button>
            </div>
          </span>
        </div>
        <div
          className={styles.blockContainer}
          style={{ margin: '20px 0 35px 0' }}
        >
          <button
            className={styles.menuButton}
            onClick={() => setScreenNumber(1)}
          >
            Повернутись до меню
          </button>
          <button
            className={styles.reviewButton}
            style={{ marginTop: 14 }}
            onClick={setReviewScreenNumber}
          >
            Далі
          </button>
        </div>
      </div>
      <WarningFooter backgroundColor="#491585" />
    </div>
  );
};

export default AuthorityScreen;
