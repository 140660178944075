import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import global from '../../index.module.scss';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useContext } from 'react';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';

const WelcomeChallengeScreen = ({
  setScreenNumber,
  title,
  subtitle,
  buttonTitle = 'Дивитися',
  description,
  backgroundImage,
  setStep,
  eventName = null,
  style = null
}) => {
  const { sendEvent } = useContext(DataLayerEventContext);
  const onButtonClickHandler = useCallback(() => {
    setStep((prev) => prev + 1);
    sendEvent(eventName || 'start_challenge');
  }, [sendEvent]);
  return (
    <div
      className={classNames(styles.WelcomeScreen, style ? styles[style] : null)}
      style={{ backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentContainer}>
        <div className={styles.headContainer}>
          <img
            src="/images/mural/mural_ellipse.png"
            alt="blue-ellipse"
            className={styles.ellipse}
          />
          <p dangerouslySetInnerHTML={{ __html: title }} />
          {subtitle && <span>{subtitle}</span>}
        </div>
        <div className={styles.bottomContainer}>
          <span
            className={styles.descrition}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <button
            className={classNames(styles.button, global.menuButton, {
              marginTop: 10
            })}
            style={{ marginTop: 10 }}
            onClick={onButtonClickHandler}
          >
            {buttonTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeChallengeScreen;
