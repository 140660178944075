import React, { useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../lm-d-s/head-container';
import WarningFooter from '../../lm-d-s/warning-footer/WarningFooter';
import CharacteristicPlusComponents from '../../../common/characteristic-plus-components';
import classNames from 'classnames';
import { Dialog, DialogContent } from '@mui/material';

const PackScreen = ({ selectedPack, setScreenNumber, onNextButtonClick }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [plusNumber, setPlusNumber] = useState(0);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <HeadContainer setScreenNumber={setScreenNumber} />
        <p className={styles.title}>{selectedPack?.title}</p>
        <div className={styles.imageContainer}>
          <img src={selectedPack?.pack} alt="pack" />
          <CharacteristicPlusComponents
            onClick={() => {
              setIsOpenDialog(true);
              setPlusNumber(1);
            }}
            className={classNames(styles.plusIcon, styles.firstPlus)}
          />
          <CharacteristicPlusComponents
            onClick={() => {
              setIsOpenDialog(true);
              setPlusNumber(2);
            }}
            className={classNames(styles.plusIcon, styles.secondPlus)}
          />
          <CharacteristicPlusComponents
            onClick={() => {
              setIsOpenDialog(true);
              setPlusNumber(3);
            }}
            className={classNames(styles.plusIcon, styles.thirdPlus)}
          />
          <CharacteristicPlusComponents
            onClick={() => {
              setIsOpenDialog(true);
              setPlusNumber(4);
            }}
            className={classNames(styles.plusIcon, styles.fourthPlus)}
          />
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.menuButton}
            onClick={() => setScreenNumber(1)}
          >
            Повернутися назад
          </button>
          <button className={styles.reviewButton} onClick={onNextButtonClick}>
            Далі
          </button>
        </div>
      </div>
      <div className={styles.footer}>
        <WarningFooter />
      </div>
      <Dialog
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        sx={{
          backdropFilter: 'blur(10px)'
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: '10px',
            top: 10
          }
        }}
        fullWidth
        maxWidth={300}
      >
        <DialogContent
          sx={{
            background: 'rgba(9, 0, 65, 0.40)',
            position: 'relative'
          }}
        >
          <img
            className={styles.closeIcon}
            src="/images/lm-special/close-dialog.png"
            alt="closeIcon"
            onClick={() => setIsOpenDialog(false)}
          />
          <div className={styles.dialogContainer}>
            {plusNumber === 1 && (
              <div className={styles.capsuleContainer}>
                <img src={selectedPack?.tobaccoIcon} alt="capsuleIcon" />
                <p>{selectedPack?.tobaccoFirst}</p>
              </div>
            )}
            {plusNumber === 2 && (
              <div className={styles.paperContainer}>
                <img src={selectedPack?.paperIcon} alt="paperIcon" />
                <p>{selectedPack?.paperText}</p>
              </div>
            )}
            {plusNumber === 3 && (
              <div className={styles.filterContainer}>
                <img src={selectedPack?.filterIcon} alt="filterIcon" />
                {selectedPack?.characteristicListTitle && (
                  <div className={styles.characteristicListTitle}>
                    {selectedPack?.characteristicListTitle}
                  </div>
                )}
                {selectedPack?.filterCharacteristicList.map((item, idx) => (
                  <p
                    key={idx}
                    style={item.bordered ? undefined : { border: 'none' }}
                  >
                    {item.title} {item.description}
                  </p>
                ))}
              </div>
            )}
            {plusNumber === 4 && (
              <div className={styles.formatContainer}>
                <img
                  src="/images/lm-double-splash/pack-info.png"
                  alt="first-plus"
                />
                <p>Формат продукту</p>
                <div className={styles.filterInfoBlock}>
                  {selectedPack?.characteristicInfoList?.map((item, idx) => (
                    <span key={idx}>
                      <span>{item.title}</span>
                      {item.description}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PackScreen;
