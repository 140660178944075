import { useMemo, useRef } from 'react';

const SHOP_LINKS = {
  '23UA-MLB-T': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=marlboro/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/marlboro/?utm_source=SP'
  },
  '23UA-MLB-F-T': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=marlboro/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/marlboro/?utm_source=SP'
  },
  '23UA-LM-6': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=b84d5fce/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/lm/?utm_source=SP'
  },
  '23UA-BND-P-6': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=bond/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/bond-street/?utm_source=SP'
  },
  '23UA-BND-P-4': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=bond/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/bond-street/?utm_source=SP'
  },
  '23UA-MPM-6': {
    rozetka:
      'https://rozetka.com.ua/ua/sigareti/c4638591/producer=philip-morris/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/philip-morris/?utm_source=SP'
  },
  '23UA-MPM-4': {
    rozetka:
      'https://rozetka.com.ua/ua/sigareti/c4638591/producer=philip-morris/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/philip-morris/?utm_source=SP'
  },
  '23UA-MPM-N-M': {
    rozetka:
      'https://rozetka.com.ua/ua/sigareti/c4638591/producer=philip-morris/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/philip-morris/?utm_source=SP'
  },
  '23UA-MPM-N-M-S': {
    rozetka:
      'https://rozetka.com.ua/ua/sigareti/c4638591/producer=philip-morris/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/philip-morris/?utm_source=SP'
  },
  '23UA-BND-P-M': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=bond/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/bond-street/?utm_source=SP'
  },
  '23UA-LM-L-M': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=b84d5fce/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/lm/?utm_source=SP'
  },
  '23UA-LM-D-S': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=b84d5fce/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/lm/?utm_source=SP'
  },
  '23UA-LM-D-S-Test': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=b84d5fce/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/lm/?utm_source=SP'
  },
  '23UA-Ches-6': {
    rozetka:
      'https://rozetka.com.ua/ua/sigareti/c4638591/producer=chesterfield/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/chesterfield-ua/?utm_source=SP'
  },
  '23UA-Ches-4': {
    rozetka:
      'https://rozetka.com.ua/ua/sigareti/c4638591/producer=chesterfield/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/chesterfield-ua/?utm_source=SP'
  },
  '23UA-PRL-S-6': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=parliament/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/parliament/?utm_source=SP'
  },
  '23UA-PRL-S-4': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=parliament/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/parliament/?utm_source=SP'
  },
  '23UA-PRL-V': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=parliament/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/parliament/?utm_source=SP'
  },
  '23UA-PRL-S-6-Test': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=parliament/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/parliament/?utm_source=SP'
  },
  '23UA-PRL-S-4-Test': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=parliament/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/parliament/?utm_source=SP'
  },
  '23UA-PRL-V-Test': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=parliament/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/parliament/?utm_source=SP'
  },
  '23UA-LM-4': {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=b84d5fce/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/lm/?utm_source=SP'
  },
  chesterfield: {
    rozetka:
      'https://rozetka.com.ua/ua/sigareti/c4638591/producer=chesterfield/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/chesterfield-ua/?utm_source=SP'
  },
  marlboro: {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=marlboro/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/marlboro/?utm_source=SP'
  },
  bond: {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=bond/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/bond-street/?utm_source=SP'
  },
  lm: {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=b84d5fce/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/lm/?utm_source=SP'
  },
  'philip-morris': {
    rozetka:
      'https://rozetka.com.ua/ua/sigareti/c4638591/producer=philip-morris/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/philip-morris/?utm_source=SP'
  },
  parliament: {
    rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=parliament/',
    cigPoshta: 'https://cig-poshta.in.ua/shop/parliament/?utm_source=SP'
  }
};

export default function useShopLinks(brandId = null) {
  const projectPathRef = useRef(sessionStorage.getItem('isQrCode'));
  projectPathRef.current = sessionStorage.getItem('isQrCode');

  const shopLink = useMemo(() => {
    if (!projectPathRef.current && !brandId) return;
    if (brandId) return SHOP_LINKS[brandId];
    const _link = SHOP_LINKS[projectPathRef.current];
    return _link ?? undefined;
  }, [brandId]);

  return { shopLink };
}
