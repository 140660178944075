import React from 'react';
import styles from './index.module.scss';

const FourthScreen = () => {
  return (
    <div className={styles.container}>
      <img src="/images/ghws/fourth.png" alt="first" />
    </div>
  );
};

export default FourthScreen;
