import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import HeadContainer from '../head-container';
import Confetti from 'react-confetti';

const AnswerField = ({ className, value, state, isSuccess }) => {
  const [valueMap, setValueMap] = useState([]);

  const checkCharState = (idx) => {
    return Boolean(state?.[idx]);
  };

  useEffect(() => {
    if (typeof value !== 'string' || !(value?.length > 0)) return;
    setValueMap(value.split(''));
  }, [value]);

  return (
    <div className={classNames(styles.answers, className)}>
      {valueMap?.map((item, idx) => {
        return (
          <span
            className={classNames(
              styles.answersItem,
              isSuccess && styles.inputSuccess
            )}
            key={idx}
          >
            {checkCharState(idx) ? item : null}
          </span>
        );
      }) || []}
    </div>
  );
};

const InputField = ({ value, state, onClick, error = false }) => {
  if (!!state) {
    return (
      <button
        className={classNames(styles.input, error && styles.inputError)}
        type="button"
        onClick={onClick}
      >
        {value}
      </button>
    );
  }
  return <span className={styles.input}></span>;
};

const InputsField = ({
  className,
  value,
  state,
  onChange,
  errorIndex = null
}) => {
  const handleClick = (idx) => {
    if (typeof onChange === 'function' && state) {
      onChange(idx);
    }
  };

  return (
    <div className={classNames(styles.inputs, className)}>
      {value?.map((item, idx) => {
        return (
          <InputField
            key={idx}
            value={item}
            onClick={() => handleClick(idx)}
            state={state?.[idx]}
            error={errorIndex === idx}
          />
        );
      })}
    </div>
  );
};

const Progress = ({
  data,
  onSuccess,
  onStepChange,
  setScreenNumber,
  backgroundImage,
  stepsCount,
  stepIndex,
  footer
}) => {
  const [errorCharacterIdx, setErrorCharacterIdx] = useState(null);
  const [isSuccessAnimation, setIsSuccessAnimation] = useState(null);
  const [isSuccessStep, setIsSuccessStep] = useState(null);

  let errorAnimationTimeout = null;
  let successAnimationTimeout = null;

  const handleValueClick = (idx) => {
    const _answer = data?.value?.answer.toLowerCase();
    const _value = `${data?.value?.characters[idx]}`?.toLowerCase();

    const indexes = [];
    for (let i = 0; i < _answer.length; i++) {
      if (_answer[i] === _value && !data?.state?.answerMap[i]) indexes.push(i);
    }
    if (!indexes || !indexes?.length) {
      setErrorCharacterIdx(idx);
      errorAnimationTimeout = setTimeout(() => {
        setErrorCharacterIdx(null);
      }, 700);
      return;
    }
    const index = indexes[0];

    const newAnswerMap = [...data?.state?.answerMap] || [];
    const newItemsMap = [...data?.state?.itemsMap] || [];
    newAnswerMap[index] = true;
    newItemsMap[idx] = false;

    if (typeof onStepChange === 'function') {
      onStepChange({
        answerMap: [...newAnswerMap],
        itemsMap: [...newItemsMap]
      });
    }
  };

  useEffect(() => {
    if (data.state.answerMap?.length > 0) {
      setIsSuccessStep(data.state.answerMap?.every((item) => item) || false);
    }
  }, [data.state.answerMap]);

  useEffect(() => {
    if (isSuccessStep) {
      if (typeof onSuccess === 'function') {
        setIsSuccessAnimation(true);
        successAnimationTimeout = setTimeout(() => {
          setIsSuccessAnimation(false);
          onSuccess();
        }, 3500);
      }
    }
  }, [isSuccessStep]);

  useEffect(() => {
    return () => {
      if (errorAnimationTimeout) clearTimeout(errorAnimationTimeout);
      if (successAnimationTimeout) clearTimeout(successAnimationTimeout);
    };
  }, []);

  return (
    <div
      className={styles.wordGame}
      style={{ backgroundImage: backgroundImage }}
    >
      {isSuccessAnimation && <Confetti tweenDuration={1500} />}
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.stepToolbar}>
        {new Array(stepsCount).fill(null).map((_, index) => (
          <div
            key={index}
            className={classNames(
              styles.stepItem,
              index === stepIndex && styles.activeStepItem
            )}
          />
        ))}
      </div>
      <div className={styles.imagesWr}>
        {data?.value?.images?.map((img, idx) => (
          <img src={img} key={idx} alt="Challenge image" />
        ))}
      </div>
      <AnswerField
        className={styles.wordGameAnswers}
        value={data?.value?.answer || null}
        state={data?.state?.answerMap || []}
        isSuccess={isSuccessStep}
      />
      <InputsField
        className={styles.wordGameInputs}
        value={data?.value?.characters || []}
        state={data?.state?.itemsMap || []}
        onChange={handleValueClick}
        errorIndex={errorCharacterIdx}
      />
      {footer}
    </div>
  );
};

export default Progress;
