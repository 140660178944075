import React from 'react';
import HeadContainer from '../../head-container';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import classNames from 'classnames';
import axios from 'axios';
import fs from 'fs';

export default function SuccessScreen({
  setScreenNumber,
  backgroundImage,
  setStep,
  result
}) {
  const downloadImage = async (imageUrl, outputPath) => {
    try {
      const response = await axios.get(imageUrl, { responseType: 'stream' });
      response.data.pipe(fs.createWriteStream(outputPath));

      return new Promise((resolve, reject) => {
        response.data.on('end', () => {
          resolve();
        });

        response.data.on('error', (err) => {
          reject(err);
        });
      });
    } catch (error) {
      throw new Error(`Error downloading the image: ${error}`);
    }
  };

  const nextClick = () => {
    setStep((prev) => prev + 1);
  };

  const prevClick = () => {
    setStep(2);
  };

  return (
    <div
      className={styles.SuccessContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentContainer}>
        <div className={styles.headContainer}>
          <p className={styles.title}>Лови своє зображення!</p>
          <div className={styles.image}>
            <img src={result} alt="updated-photo" />
            {/*<iframe*/}
            {/*  src={result}*/}
            {/*  width="100%"*/}
            {/*  height="100%"*/}
            {/*  sandbox="allow-same-origin"*/}
            {/*/>*/}
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.buttonsRow}>
            <button
              className={classNames(styles.button, global.reviewButton)}
              onClick={prevClick}
            >
              Назад
            </button>
            <button
              className={classNames(styles.button, global.menuButton)}
              onClick={nextClick}
            >
              Далі
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
