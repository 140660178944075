import React, { useState, useCallback, useRef } from 'react';
import styles from './index.module.scss';
import Webcam from 'react-webcam';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

const videoConstraints = {
  facingMode: FACING_MODE_USER
};

export default function WebcamScreen({
  setStep,
  imgSrc,
  setImgSrc,
  setCompleted,
  generateImage
}) {
  const webcamRef = useRef(null);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

  const changeCamera = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  async function nextStep() {
    const webcamImg = await webcamRef.current.getScreenshot({
      width: 512,
      height: 512
    });
    generateImage(webcamImg);
    // const base64Png = await webcamImg?.replace('jpeg', 'png');
    // let converted = null;
    // const http = new XMLHttpRequest();
    // http.onload = () => {
    //   converted = http.response;
    //   if (converted) {
    //
    //   }
    // };
    // http.responseType = 'blob';
    // http.open('GET', base64Png, true);
    // http.send();
    setCompleted(0);
    setStep((prev) => prev + 1);
  }
  return (
    <div className={styles.WebcamScreen}>
      <Webcam
        minScreenshotWidth={512}
        minScreenshotHeight={512}
        ref={webcamRef}
        screenshotFormat="image/png"
        style={{
          width: '100%',
          height: '100%',
          aspectRatio: '9 / 16'
        }}
      />
      <button className={styles.button} onClick={nextStep} />
      <button className={styles.rightButton} onClick={changeCamera}>
        <img src="/images/mural/camera_button.svg" alt="button" />
      </button>
    </div>
  );
}
