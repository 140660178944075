import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../components/lm-d-s/head-container';
import WarningFooter from '../../lm-d-s/warning-footer/WarningFooter';

const lineList = [
  {
    image: 'images/packs/lm/loft-purple.png',
    title: 'L&M Loft Purple',
    pageNumber: 21
  },
  {
    image: 'images/packs/lm/loft-green.png',
    title: 'L&M Loft Green',
    pageNumber: 17
  },
  {
    image: 'images/packs/philip-morris/novel-purple.png',
    title: 'Philip Morris Novel Purple',
    pageNumber: 22
  },
  {
    image: 'images/packs/chesterfield/retuned.png',
    title: 'Chesterfield Retuned',
    pageNumber: 18
  },
  {
    image: 'images/bond/mix/pack.png',
    title: 'Bond Street Premium Mix',
    pageNumber: 20
  },
  {
    image: 'images/pmn/pmnms/pack.png',
    title: 'Philip Morris Novel Mix Summer',
    pageNumber: 19
  }
];

const WelcomeScreen = ({ backgroundImage, setScreenNumber }) => {
  return (
    <div className={styles.wrapperContainer}>
      <div
        className={styles.container}
        style={{ backgroundImage: backgroundImage }}
      >
        <HeadContainer setScreenNumber={setScreenNumber} />
        <div className={styles.infoContainer}>
          <p>Сигарети</p>
          <span>L&M Loft Mix</span>
          <p className={styles.description}>
            скоро зникнуть з полиць магазинів згідно з новим
            <span onClick={() => setScreenNumber(2)}> законодавством.</span>
          </p>
          <img src="/images/packs/lm/loft-mix.png" alt="pack" />
          <img
            src="/images/lm-double-splash/red-ribbons.png"
            alt="ribbons"
            className={styles.ribbons}
          />
          <p className={styles.bottomTitle} onClick={() => setScreenNumber(4)}>
            L&M Loft Mix
          </p>
        </div>
        <div className={styles.lineContainer}>
          <p>Тому звертаємо увагу</p>
          <p>на інші сигарети на заміну, які доступні до продажу:</p>
          <div className={styles.lineListContainer}>
            {lineList.map((item) => {
              return (
                <div
                  className={styles.lineItem}
                  onClick={() => setScreenNumber(item.pageNumber)}
                >
                  <div className={styles.imageContainer}>
                    <img src={item.image} alt={item.image} />
                  </div>
                  <p>{item.title}</p>
                  <img
                    className={styles.arrow}
                    src="/images/lm-double-splash/rigth-circle-arrow.svg"
                    alt="arrow-right"
                  />
                </div>
              );
            })}
          </div>
        </div>
        <button
          className={styles.nextButton}
          onClick={() => setScreenNumber(1)}
        >
          Далі
        </button>
      </div>
      <WarningFooter />
    </div>
  );
};

export default WelcomeScreen;
