import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import styles from "./VideoComponent.module.css";
import classNames from "classnames/bind";
import { ReactComponent as VolumeOnImage } from "../../icons/volume_on.svg";
import { ReactComponent as VolumeOffImage } from "../../icons/volume_off.svg";
// import { ReactComponent as FullScreenImage } from '../../icons/full_screen.svg';
// import { ReactComponent as FullScreenOffImage } from '../../icons/full_screen_off.svg';
let cx = classNames.bind(styles);

function VideoComponent({ sourcePath, onVideoEnd, controls = true }, ref) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [controlBtnsAnimated, setcontrolBtnsAnimated] = useState(false);
  const [controlBtnsVisible, setcontrolBtnsVisible] = useState(controls);
  const [muted, setMuted] = useState(false);
  // const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentVideoTime, setCurrentVideoTime] = useState("00:00");
  const [fullVideoTimeSeconds, setFullVideoTimeInSeconds] = useState(0);
  const [fullVideoTime, setfullVideoTime] = useState("00:00");
  const [timeLinePlaceholderWidth, setTimeLinePlaceholderWidth] = useState(0);
  const [firstPlay, setFirstPlay] = useState(false);

  const pouseTimeoutRef = useRef(null);
  const videoRef = useRef(null);
  const videoTimeLine = useRef(null);
  const videoContainer = useRef(null);

  useImperativeHandle(ref, () => ({
    playBtnHandler: (e) => {
      e.stopPropagation();
      setIsPlaying(true);
      clearControllsAnimation();
      hideVideoControllsWithAnimation();
      videoRef.current.play();
    },
  }));

  useEffect(() => {
    let videoElement = videoRef.current;
    function metaIsLoaded() {
      let time = Math.floor(videoElement.duration);
      let minutes = Math.floor(time / 60);
      let sconds = time % 60;
      let secondsScring = `${sconds}`.length === 2 ? `${sconds}` : `0${sconds}`;
      let minutesScring =
        `${minutes}`.length >= 2 ? `${minutes}` : `0${minutes}`;
      setfullVideoTime(`${minutesScring}:${secondsScring}`);
      setFullVideoTimeInSeconds(time);
    }
    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", metaIsLoaded);
      return () => {
        videoElement.removeEventListener("loadedmetadata", metaIsLoaded);
      };
    }
  }, [fullVideoTime, fullVideoTimeSeconds]);

  const hideVideoControllsWithAnimation = () => {
    if (controls) {
      setcontrolBtnsVisible(true);
    }
    setcontrolBtnsAnimated(true);
    pouseTimeoutRef.current = setTimeout(() => {
      setcontrolBtnsAnimated(false);
      if (controls) {
        setcontrolBtnsVisible(false);
      }
    }, 2000);
  };

  const clearControllsAnimation = () => {
    clearTimeout(pouseTimeoutRef.current);
  };

  const videoTimeUpdateHandle = (e) => {
    let currentSeconds = Math.floor(e.target.currentTime);
    if (currentVideoTime !== currentSeconds) {
      let minutes = Math.floor(currentSeconds / 60);
      let sconds = currentSeconds % 60;
      let secondsScring = `${sconds}`.length === 2 ? `${sconds}` : `0${sconds}`;
      let minutesScring =
        `${minutes}`.length >= 2 ? `${minutes}` : `0${minutes}`;
      setCurrentVideoTime(`${minutesScring}:${secondsScring}`);
      let percentOfVideoTime = currentSeconds / fullVideoTimeSeconds;
      setTimeLinePlaceholderWidth(percentOfVideoTime * 100);
    }
  };

  const videoEndedHandler = (e) => {
    if (onVideoEnd) onVideoEnd();
  };

  const playBtnHandler = (e) => {
    e.stopPropagation();
    setIsPlaying(true);
    clearControllsAnimation();
    hideVideoControllsWithAnimation();
    videoRef.current.play();
  };

  const videoClickHandler = (e) => {
    e.stopPropagation();
    clearControllsAnimation();
    if (controlBtnsVisible) {
      videoRef.current.pause();
      setIsPlaying(false);
      setcontrolBtnsAnimated(false);
    } else {
      hideVideoControllsWithAnimation();
    }
  };

  const trayPlayBtnClickHandle = (e) => {
    e.stopPropagation();
    clearControllsAnimation();
    if (isPlaying) {
      setIsPlaying(false);
      setcontrolBtnsAnimated(false);
      videoRef.current.pause();
    } else {
      setIsPlaying(true);
      videoRef.current.play();
      hideVideoControllsWithAnimation();
    }
  };

  // const fullScreenBtnClickHandle = () => {
  //     clearControllsAnimation();
  //     if( isPlaying ) {
  //         hideVideoControllsWithAnimation();
  //     }
  //     let videoElements = videoContainer.current;
  //     if ( !isFullScreen ) {
  //         if (videoElements.requestFullScreen) videoElements.requestFullScreen();
  //         else if (videoElements.webkitRequestFullScreen) videoElements.webkitRequestFullScreen();
  //         else if (videoElements.mozRequestFullScreen) videoElements.mozRequestFullScreen();
  //         setIsFullScreen(true);
  //     } else {
  //       if (document.exitFullscreen) {
  //         document.exitFullscreen();
  //       } else if (document.mozCancelFullScreen) {
  //         document.mozCancelFullScreen();
  //       } else if (document.webkitExitFullscreen) {
  //         document.webkitExitFullscreen();
  //       }
  //       setIsFullScreen(false);
  //     }
  // }

  const timerBgClickHandler = (e) => {
    clearControllsAnimation();
    if (isPlaying) {
      hideVideoControllsWithAnimation();
    }
    var posX = e.clientX;
    let fromLeft = e.currentTarget.getBoundingClientRect().x;
    let fullLength = e.currentTarget.offsetWidth;
    let percentageLength = (posX - fromLeft) / fullLength;
    videoRef.current.currentTime = Math.floor(
      fullVideoTimeSeconds * percentageLength,
    );
    setTimeLinePlaceholderWidth(percentageLength * 100);
  };

  const volumeBtnClickHandle = () => {
    clearControllsAnimation();
    if (isPlaying) {
      hideVideoControllsWithAnimation();
    }
    setMuted(!muted);
  };

  const videoAutoplayHandler = (e) => {
    if (!firstPlay) {
      setFirstPlay(true);
      playBtnHandler(e);
    }
  };

  let videoBtnImgClass = cx({
    playBtnImg: !isPlaying,
    pauseBtnImg: isPlaying,
  });

  let videoBtnClass = cx({
    playBtn: true,
    pauseBtnAnimation: controlBtnsAnimated,
    pauseBtnVisible: controlBtnsVisible,
  });

  let trayPalyBtnClass = cx({
    trayPlayBtn: !isPlaying,
    trayPauseBtn: isPlaying,
  });

  return (
    <div className={styles.playerWr} ref={videoContainer}>
      <video
        onPlay={videoAutoplayHandler}
        autoPlay
        playsInline
        ref={videoRef}
        className={styles.player}
        onClick={videoClickHandler}
        onTimeUpdate={videoTimeUpdateHandle}
        onEnded={videoEndedHandler}
        muted={muted}
        controls={controls}
        src={sourcePath}
      ></video>
      {controlBtnsVisible && (
        <>
          <button className={videoBtnClass} onClick={playBtnHandler}>
            <div className={videoBtnImgClass}></div>
          </button>
          <div className={styles.trayBox}>
            <div
              className={trayPalyBtnClass}
              onClick={trayPlayBtnClickHandle}
            ></div>
            <div className={styles.timer}>
              <div
                className={styles.timerBg}
                ref={videoTimeLine}
                onClick={timerBgClickHandler}
              >
                <div
                  className={styles.timerPlaceholder}
                  style={{ width: timeLinePlaceholderWidth + "%" }}
                ></div>
              </div>
              <div className={styles.timerTime}>
                {currentVideoTime} / {fullVideoTime}
              </div>
            </div>
            <button className={styles.volume} onClick={volumeBtnClickHandle}>
              {muted ? <VolumeOffImage /> : <VolumeOnImage />}
            </button>
            {/* <button className={styles.fullScreen} onClick={fullScreenBtnClickHandle}>
                            { isFullScreen ? <FullScreenOffImage /> : <FullScreenImage /> }
                        </button> */}
          </div>
        </>
      )}
    </div>
  );
}

export default forwardRef(VideoComponent);
