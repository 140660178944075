import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../components/lm-d-s/head-container';
import WarningFooter from '../warning-footer/WarningFooter';

const lineList = [
  {
    image: 'images/lm-loft-mix/pack.png',
    title: 'L&M Loft Mix'
  },
  {
    image: 'images/bond/mix/pack.png',
    title: 'Bond Street Premium Mix'
  },
  {
    image: 'images/pmn/pmnm/pack.png',
    title: 'Philip Morris Novel Mix'
  },
  {
    image: 'images/pmn/pmnms/pack.png',
    title: 'Philip Morris Novel Mix Summer'
  }
];

const WelcomeScreen = ({ backgroundImage, setScreenNumber }) => {
  return (
    <div className={styles.wrapperContainer}>
      <div
        className={styles.container}
        style={{ backgroundImage: backgroundImage }}
      >
        <HeadContainer setScreenNumber={setScreenNumber} />
        <div className={styles.infoContainer}>
          <p>Сигарети L&M LOFT</p>
          <span>Double </span>
          <span>Splash</span>
          <p className={styles.description}>
            скоро зникнуть з полиць магазинів згідно з новим
            <span onClick={() => setScreenNumber(2)}> законодавством.</span>
          </p>
          <img src="/images/lm-double-splash/pack.png" alt="pack" />
          <img
            src="/images/lm-double-splash/red-ribbons.png"
            alt="ribbons"
            className={styles.ribbons}
          />
          <p className={styles.bottomTitle} onClick={() => setScreenNumber(4)}>
            L&M Loft Double Splash
            <img
              src="/images/lm-double-splash/rigth-circle-arrow.svg"
              alt="arrow"
            />
          </p>
        </div>
        <div className={styles.lineContainer}>
          <p>Тому звертаємо увагу</p>
          <p>на інші сигарети з капсулами, які поки залишаються доступними*:</p>
          <div className={styles.lineListContainer}>
            {lineList.map((item) => {
              return (
                <div className={styles.lineItem}>
                  <div className={styles.imageContainer}>
                    <img src={item.image} alt={item.image} />
                  </div>
                  <p>{item.title}</p>
                </div>
              );
            })}
          </div>
          <div className={styles.info}>
            * Залишки продукту, які можуть продаватися до 11 липня 2024 року
            відповідно до закону
          </div>
        </div>
        <button
          className={styles.nextButton}
          onClick={() => setScreenNumber(1)}
        >
          Далі
        </button>
      </div>
      <WarningFooter />
    </div>
  );
};

export default WelcomeScreen;
