import React from 'react';
import styles from './index.module.scss';

const ForesightResultScreen = ({ handleNextScreen }) => {
  return (
    <div className={styles.container}>
      <div>
        <p className={styles.title}>Надихнувся передбаченням?</p>
        <p className={styles.description}>
          Цього тижня воно точно справдиться!
        </p>
      </div>
      <button className={styles.menuButton} onClick={handleNextScreen}>
        Далі
      </button>
    </div>
  );
};

export default ForesightResultScreen;
