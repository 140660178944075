import React from 'react';

export default function ProgressBar({ completed }) {
  const containerStyles = {
    position: 'relative',
    height: 33,
    background: 'rgba(12, 54, 117, 0.5)',
    backdropFilter: 'blur(1.5px)',
    borderRadius: '8px'
  };

  const fillerStyles = {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: `${completed}%`,
    backgroundColor: '#F8A629',
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 1s'
  };

  const labelStyles = {
    position: 'absolute',
    top: '-60px',
    left: `${Math.min(Math.max(parseInt(completed), 0), 75)}%`,
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '120%',
    textTransform: 'uppercase',
    color: '#F8A629'
  };

  return (
    <div style={containerStyles}>
      <span style={labelStyles}>{`${completed}%`}</span>
      <div style={fillerStyles}></div>
    </div>
  );
}
