import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../components/lm-d-s/head-container';

const lineList = [
  {
    image: '/images/lm-double-splash/line/1.png',
    title: 'Компакт\n' + 'З капсулою'
  },
  {
    image: '/images/lm-double-splash/line/2.png',
    title: 'Компакт'
  },
  {
    image: '/images/lm-double-splash/line/3.png',
    title: 'Кінг Сайз'
  },
  {
    image: '/images/lm-double-splash/line/4.png',
    title: 'Кінг Сайз\n' + '40 шт. у пачці'
  }
];

const LmLineScreen = ({ setScreenNumber }) => {
  return (
    <div className={styles.screenContainer}>
      <HeadContainer setScreenNumber={setScreenNumber} />
      <span className={styles.title}>Лінійка L&M</span>
      <div className={styles.lineContainer}>
        {lineList.map((item, index) => (
          <div key={index.toString()} className={styles.lineItem}>
            <img src={item.image} alt={item.image} />
            <span>
              <p>{item.title}</p>
            </span>
          </div>
        ))}
      </div>
      <p className={styles.description}>
        Актуальний асортимент можна знайти на офіційних онлайн сервісах або у
        роздрібних точках продажу в твоєму місті
      </p>
      <div className={styles.linkButtonContainer}>
        <a
          className={styles.linkButton}
          href="https://cig-poshta.in.ua/shop/lm/"
          target="_blank"
        >
          Cig-poshta
        </a>
        <a
          className={styles.linkButton}
          href="https://rozetka.com.ua/ua/sigareti/c4638591/producer=b84d5fce/"
          target="_blank"
        >
          Rozetka
        </a>
      </div>
      <button
        className={styles.continueButton}
        onClick={() => setScreenNumber(10)}
      >
        Далі
      </button>
    </div>
  );
};

export default LmLineScreen;
