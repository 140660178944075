import React from 'react';
import styles from './index.module.scss';

const ResultScreen = () => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>Дякуємо за приділений час!</p>
      <p className={styles.description}>Сподіваємося, що було корисно.</p>
      <img src="/images/ghws/result.png" alt="result" />
    </div>
  );
};

export default ResultScreen;
