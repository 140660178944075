export const twoSpecialCapsuleChallenge = [
  {
    id: '1',
    title: 'Хто з них більше нагадує тобі себе?',
    imageList: [
      {
        id: '1',
        url: '/images/special-capsules/two-image-challenge/1.1.png'
      },
      {
        id: '2',
        url: '/images/special-capsules/two-image-challenge/1.2.png'
      }
    ]
  },
  {
    id: '2',
    title: 'Де тобі найкраще відпочити на вихідних?',
    imageList: [
      {
        id: '1',
        url: '/images/special-capsules/two-image-challenge/2.1.png'
      },
      {
        id: '2',
        url: '/images/special-capsules/two-image-challenge/2.2.png'
      }
    ]
  },
  {
    id: '3',
    title: 'Який з цих десертів для тебе найапетитніший?',
    imageList: [
      {
        id: '1',
        url: '/images/special-capsules/two-image-challenge/3.1.png'
      },
      {
        id: '2',
        url: '/images/special-capsules/two-image-challenge/3.2.png'
      }
    ]
  },
  {
    id: '4',
    title: 'Чим зап’єш десерт?',
    imageList: [
      {
        id: '1',
        url: '/images/special-capsules/two-image-challenge/4.1.png'
      },
      {
        id: '2',
        url: '/images/special-capsules/two-image-challenge/4.2.png'
      }
    ]
  },
  {
    id: '5',
    title: 'Де тобі буде затишніше подрімати?',
    imageList: [
      {
        id: '1',
        url: '/images/special-capsules/two-image-challenge/5.1.png'
      },
      {
        id: '2',
        url: '/images/special-capsules/two-image-challenge/5.2.png'
      }
    ]
  }
];

export const twoSpecialCapsuleResultList = [
  {
    resultId: '1',
    title: 'Лісові ягоди',
    image: '/images/special-capsules/two-image-challenge/forest-berries.png'
  },
  {
    resultId: '2',
    title: 'Кавун',
    image: '/images/special-capsules/two-image-challenge/watermelon.png'
  }
];

export const yourTopSpecialCapsuleChallenge = [
  {
    id: '1',
    url: '/images/special-capsules/your-top-challenge/1.png',
    text: 'Вироби з діамантів'
  },
  {
    id: '2',
    url: '/images/special-capsules/your-top-challenge/2.png',
    text: 'Персональний басейн'
  },
  {
    id: '3',
    url: '/images/special-capsules/your-top-challenge/3.png',
    text: 'Приватні літаки'
  },
  {
    id: '4',
    url: '/images/special-capsules/your-top-challenge/4.png',
    text: 'Яхти'
  },
  {
    id: '5',
    url: '/images/special-capsules/your-top-challenge/5.png',
    text: 'Спорткари'
  },
  {
    id: '6',
    url: '/images/special-capsules/your-top-challenge/6.png',
    text: 'Екзотична відпустка'
  },
  {
    id: '7',
    url: '/images/special-capsules/your-top-challenge/7.png',
    text: 'Трюфелі'
  },
  {
    id: '8',
    url: '/images/special-capsules/your-top-challenge/8.png',
    text: 'Устриці'
  },
  {
    id: '9',
    url: '/images/special-capsules/your-top-challenge/9.png',
    text: 'Власний острів'
  },
  {
    id: '10',
    url: '/images/special-capsules/your-top-challenge/10.png',
    text: 'Дорогі годинники'
  }
];
