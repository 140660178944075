import React, { memo } from 'react';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMemo } from 'react';

export default memo(function ProgressAnswerStep({ nextStep, data }) {
  const answerStyle = useMemo(() => {
    return data.key
      ? {
          background:
            'linear-gradient(89deg, rgba(60, 149, 255, 0.70) 0%, rgba(0, 91, 198, 0.70) 100%)'
        }
      : {
          background:
            'linear-gradient(89deg, rgba(255, 77, 119, 0.70) 0%, rgba(198, 0, 0, 0.70) 100%)'
        };
  }, [data.key]);
  return (
    <div>
      <div className={styles.bodyContainer}>
        <div className={styles.title} style={{ fontSize: 32, ...answerStyle }}>
          <p>{data.title}</p>
        </div>
        <div className={styles.image}>
          <LazyLoadImage
            src={data.image}
            width={'100%'}
            height={279}
            alt={data.title}
          />
          <div className={styles.description}>
            <p>{data.description}</p>
          </div>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <button className={global.menuButton} onClick={nextStep}>
          Далі
        </button>
      </div>
    </div>
  );
});
