import React, { useEffect, useState } from 'react';
import ResultInfoScreen from '../result-info-screen';
import WelcomeChallengeScreen from '../welcome-challenge-screen';
import Progress from './Progress';

export default function InstantQuizChallenge({
  setScreenNumber,
  type,
  backgroundImage,
  resultTitleFontSize,
  setScreenNumberAfterResult
}) {
  const [status, setStatus] = useState('start'); // start | in_progress | finished
  const [step, setStep] = useState(0);
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const { start, challengeList, quizResultList, finish } = await import(
        `./utils/${type}.js`
      );
      const [_start, _challengeList, _quizResultList, _finish] =
        await Promise.all([start, challengeList, quizResultList, finish]);
      setData({
        start: _start,
        challengeList: _challengeList,
        quizResultList: _quizResultList,
        finish: _finish
      });
    })();
  }, [type]);

  if (status === 'start')
    return (
      <WelcomeChallengeScreen
        setScreenNumber={setScreenNumber}
        backgroundImage={backgroundImage}
        title={data?.start.title}
        description={data?.start.description}
        buttonTitle={data?.start.button}
        setNextScreenNumber={() => setStatus('in_progress')}
        eventName={'start_challenge_test'}
      />
    );
  if (status === 'in_progress')
    return (
      <Progress
        list={data?.challengeList}
        resultList={data?.quizResultList}
        setScreenNumber={setScreenNumber}
        backgroundImage={backgroundImage}
        resultTitleFontSize={resultTitleFontSize}
        step={step}
        setStep={setStep}
        setStatus={setStatus}
      />
    );
  if (status === 'finished')
    return (
      <ResultInfoScreen
        setScreenNumber={setScreenNumber}
        setNextScreenNumber={setScreenNumberAfterResult}
        title={data?.finish.title}
        description={data?.finish.description}
        image={data?.finish.image}
        backgroundImage={backgroundImage}
      />
    );
}
