import React from "react";

export const fiveSohoFactsChallengeList = [
    {
        title: "Мистецька мекка",
        description: (
            <p>Коли в 70-х роках індустріальні будівлі району перетворили на житлові, Сохо став улюбленим місцем митців із різних куточків світу. Тепер тут є багато галерей, стінописів та художніх інсталяцій.</p>
        ),
        image: "/images/parliament/challenge/soho-facts/1.png",
    },
    {
        title: "Чавунні фасади",
        description: (
            <p>Цікава архітектурна особливість Сохо — фасади будівель із чавуну. Вони прикрашають вулиці своїми вишуканими орнаментами й історичною елегантністю.</p>
        ),
        image: "/images/parliament/challenge/soho-facts/2.png",
    },
    {
        title: "Маленька Італія",
        description: (
            <p>Колись у частині Сохо розташовувалася Маленька Італія — район із жителями італійського походження. Цю частину історії сьогодні можна побачити в меню ресторанів та в культурних заходах Сохо.</p>
        ),
        image: "/images/parliament/challenge/soho-facts/3.png",
    },
    {
        title: "Зірка кіно",
        description: (
            <p>Завдяки своїй атмосфері Сохо став знімальним майданчиком для багатьох фільмів. Тут можна впізнати декорації, знайомі нам із великих екранів.</p>
        ),
        image: "/images/parliament/challenge/soho-facts/4.png",
    },
    {
        title: "Мода та стиль",
        description: (
            <p>У Сохо безмежна кількість бутиків від світових брендів та місцевих дизайнерських магазинчиків. Саме тому це справжній магніт для модних ентузіастів.</p>
        ),
        image: "/images/parliament/challenge/soho-facts/5.png",
    },
];
