import React from 'react';

export const brandList = [
  {
    brandId: 'chesterfield',
    background: `url("/images/lm-double-splash/violet-background.png")`,
    brandTitle: 'Chesterfield',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'Chesterfield Original 40',
            pack: '/images/packs/chesterfield/original-40.png',
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/chesterfield-4/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 30'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 8'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Chesterfield Blue 40',
            pack: '/images/packs/chesterfield/blue-40.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/chesterfield-4/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 30'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 8'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Chesterfield Original',
            pack: '/images/packs/chesterfield/original.png',
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/chesterfield-4/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Chesterfield Blue',
            pack: '/images/packs/chesterfield/blue.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/chesterfield-4/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Компактна лінійка',
        packsLine: [
          {
            title: 'Chesterfield Compact Orange',
            pack: '/images/packs/chesterfield/orange.png',
            background: `url("/images/lm-special/new-background.png")`,
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: '/images/parliament/filter.png',
            isDarkButton: false,
            isNewMixture: true,
            newMixtureText: 'Абсолютно нова тютюнова суміш',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з двох <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Chesterfield Retuned',
            pack: '/images/packs/chesterfield/retuned.png',
            background: `url("/images/lm-special/new-background.png")`,
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/parliament/filter.png',
            isNewMixture: true,
            newMixtureText: 'Особлива тютюнова суміш',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з двох <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Chesterfield Compact Blue',
            pack: '/images/packs/chesterfield/compact-blue.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/chesterfield-4/filter-icon.png',
            background: `url("/images/chesterfield-4/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Chesterfield Compact Silver',
            pack: '/images/packs/chesterfield/compact-silver.png',
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: '/images/chesterfield-4/filter-icon.png',
            background: `url("/images/chesterfield-4/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'НОВІТНІ ТЮТЮНОВІ ВИРОБИ з капсулою',
        packsLine: [
          {
            title: 'Chesterfield Special Purple',
            pack: '/images/packs/chesterfield/special-purple.png',
            tobaccoSecond: '',
            tobaccoIcon: '/images/lm-special/capsule-berries.png',
            tobaccoFirst: 'Містить капсулу з присмаком лісових ягід',
            paperIcon: '/images/lm-special/paper.png',
            paperText:
              'Загорнуті в сигаретний папір, який зроблений з відновленого тютюну',
            filterIcon: '/images/lm-special/filter.png',
            background: `url("/images/lm-special/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість новітніх продуктів у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з двох <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Chesterfield SPECIAL RED',
            pack: '/images/packs/chesterfield/special-red.png',
            tobaccoIcon: '/images/lm-special/capsule-fruit.png',
            tobaccoFirst: 'Містить капсулу з присмаком кавуна',
            paperIcon: '/images/lm-special/paper.png',
            paperText:
              'Загорнуті в сигаретний папір, який зроблений з відновленого тютюну',
            tobaccoSecond: '',
            filterIcon: '/images/lm-special/filter.png',
            background: `url("/images/lm-special/background.png")`,
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з двох <br /> частин:
                </p>
              </div>
            ),
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість новітніх продуктів у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    brandId: 'marlboro',
    background: `url("/images/lm-double-splash/violet-background.png")`,
    brandTitle: 'Marlboro',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'Marlboro Red XXL 40',
            pack: '/images/packs/marlboro/red-xxl-40.png',
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: '/images/marlboro/filter.png',
            background: `url("/images/marlboro/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 40'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 5'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Marlboro Gold XXL 40',
            pack: '/images/packs/marlboro/gold-xxl-40.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/marlboro/filter.png',
            background: `url("/images/marlboro/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 40'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 5'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Marlboro Red',
            pack: '/images/packs/marlboro/red.png',
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: '/images/marlboro/filter.png',
            background: `url("/images/marlboro/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Marlboro Gold',
            pack: '/images/packs/marlboro/gold.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/marlboro/filter.png',
            background: `url("/images/marlboro/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Marlboro Silver',
            pack: '/images/packs/marlboro/silver.png',
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: '/images/marlboro/filter.png',
            background: `url("/images/marlboro/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Компактна лінійка',
        packsLine: [
          {
            title: 'Marlboro Touch Red',
            pack: '/images/packs/marlboro/touch-red.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.6',
            filterIcon: '/images/chesterfield-4/filter-icon.png',
            background: `url("/images/marlboro/special-background.png")`,
            isMarlboroSpecial: true,
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з 3-x <br /> частин:
                </p>
              </div>
            ),
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 90-95 mm Slims (90-95мм Cлімc)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Marlboro Touch Gold',
            pack: '/images/packs/marlboro/touch-gold.png',
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: '/images/chesterfield-4/filter-icon.png',
            background: `url("/images/marlboro/special-background.png")`,
            isMarlboroSpecial: true,
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з 3-x <br /> частин:
                </p>
              </div>
            ),
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 90-95 mm Slims (90-95мм Cлімc)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Marlboro Fine Touch',
            pack: '/images/packs/marlboro/fine-touch.png',
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: '/images/marlboro/filter.png',
            background: `url("/images/marlboro/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Marlboro Touch',
            pack: '/images/packs/marlboro/touch.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/marlboro/filter.png',
            background: `url("/images/marlboro/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          }
        ]
      }
    ]
  },
  {
    brandId: 'bond',
    background: `url("/images/lm-double-splash/violet-background.png")`,
    brandTitle: 'BOND STREET',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'Bond Street Red Selection',
            pack: '/images/packs/bond/red-selection.png',
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/bond/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Bond Street Blue Selection',
            pack: '/images/packs/bond/blue-selection.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/bond/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Bond Street Blue Selection 25',
            pack: '/images/packs/bond/blue-selection-25.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/bond/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 25'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 8'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Ущільнений ацетатний фільтр, який краще тримає форму та
                    менше розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Компактна лінійка',
        packsLine: [
          {
            title: 'Bond Street Premium Blue',
            pack: '/images/packs/bond/premium-blue.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/bond/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    <b>Класичний ацетатний фільтр</b> з високоякісної целюлози,
                    який затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Bond Street Premium Silver',
            pack: '/images/packs/bond/premium-silver.png',
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/bond/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    <b>Класичний ацетатний фільтр</b> з високоякісної целюлози,
                    який затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          }
          // {
          //   title: 'Bond Street Premium Mix',
          //   pack: '/images/packs/bond/premium-mix.png',
          //   tobaccoFirst: 'Вміст смол, мг - 5',
          //   tobaccoSecond: 'Вміст нікотину, мг - 0.4',
          //   filterIcon: '/images/bond/filter.png',
          //   characteristicInfoList: [
          //     {
          //       title: 'Формат ',
          //       description: '- King Size Slim (Кінг сайз слім)'
          //     },
          //     {
          //       title: 'Кількість сигарет у пачці ',
          //       description: '– 20'
          //     },
          //     {
          //       title: 'Кількість пачок у блоці ',
          //       description: '– 10'
          //     }
          //   ],
          //   filterCharacteristicList: [
          //     {
          //       description: (
          //         <span>
          //           <b>Класичний ацетатний фільтр</b> з високоякісної целюлози,
          //           який затримує тверді частинки сигаретного диму. Містить
          //           капсулу зі смаком лісових ягід.
          //         </span>
          //       ),
          //       bordered: false
          //     }
          //   ]
          // }
        ]
      }
    ]
  },
  {
    brandId: 'lm',
    background: `url("/images/lm-double-splash/violet-background.png")`,
    brandTitle: 'L&M',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'L&M Red Label XXL 40',
            pack: '/images/packs/lm/red-label-xxl-40.png',
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/lm-double-splash/menu-background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 40'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 5'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'L&M Blue Label XXL 40',
            pack: '/images/packs/lm/blue-label-xxl-40.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/lm-double-splash/menu-background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 40'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 5'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'L&M Red Label',
            pack: '/images/packs/lm/red-label.png',
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/lm-double-splash/menu-background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'L&M Blue Label',
            pack: '/images/packs/lm/blue-label.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/bond/filter.png',
            background: `url("/images/lm-double-splash/menu-background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з високоякісної целюлози, який
                    затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Компактна лінійка',
        packsLine: [
          {
            title: 'L&M Loft Splash',
            pack: '/images/packs/lm/loft-splash.png',
            background: `url("/images/lm-special/new-background.png")`,
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: '/images/parliament/filter.png',
            isDarkButton: false,
            isNewMixture: true,
            newMixtureText: 'Абсолютно нова тютюнова суміш',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з двох <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'L&M Loft Purple',
            pack: '/images/packs/lm/loft-purple.png',
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/lm-special/new-background.png")`,
            isNewMixture: true,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'L&M Loft Green',
            pack: '/images/packs/lm/loft-green.png',
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/lm-special/new-background.png")`,
            isNewMixture: true,
            newMixtureText: 'Особлива тютюнова суміш',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'L&M Loft Blue',
            pack: '/images/packs/lm/loft-blue.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/lm-double-splash/menu-background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'L&M Loft Sea Blue',
            pack: '/images/packs/lm/loft-sea-blue.png',
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/lm-double-splash/menu-background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
          // {
          //   title: 'L&M Loft Double Splash',
          //   pack: '/images/packs/lm/loft-double-splash.png',
          //   tobaccoFirst: 'Вміст смол, мг - 6',
          //   tobaccoSecond: 'Вміст нікотину, мг - 0.5',
          //   filterIcon: '/images/lm-double-splash/filter-icon.png',
          //   characteristicInfoList: [
          //     {
          //       title: 'Формат ',
          //       description: '- King Size Slim (Кінг сайз слім)'
          //     },
          //     {
          //       title: 'Кількість сигарет у пачці ',
          //       description: '– 20'
          //     },
          //     {
          //       title: 'Кількість пачок у блоці ',
          //       description: '– 10'
          //     }
          //   ],
          //   filterCharacteristicList: [
          //     {
          //       description: (
          //         <span>
          //           Класичний ацетатний фільтр з високоякісної целюлози, який
          //           затримує тверді частинки сигаретного диму. Містить капсулу
          //           зі смаком зелених соковитих фруктів та капсулу зі смаком
          //           літнього нектару.
          //         </span>
          //       ),
          //       bordered: true
          //     }
          //   ]
          // },
          // {
          //   title: 'L&M Loft Mix',
          //   pack: '/images/packs/lm/loft-mix.png',
          //   tobaccoFirst: 'Вміст смол, мг - 5',
          //   tobaccoSecond: 'Вміст нікотину, мг - 0.4',
          //   filterIcon: '/images/lm-loft-mix/filter.png',
          //   characteristicInfoList: [
          //     {
          //       title: 'Формат ',
          //       description: '- King Size Slim (Кінг сайз слім)'
          //     },
          //     {
          //       title: 'Кількість сигарет у пачці ',
          //       description: '– 20'
          //     },
          //     {
          //       title: 'Кількість пачок у блоці ',
          //       description: '– 10'
          //     }
          //   ],
          //   filterCharacteristicList: [
          //     {
          //       description: (
          //         <span>
          //           <b>Класичний ацетатний фільтр</b>
          //           <br /> з високоякісної целюлози, який затримує тверді
          //           частинки сигаретного диму. Містить капсулу зі смаком лісових
          //           ягід.
          //         </span>
          //       ),
          //       bordered: false
          //     }
          //   ]
          // }
        ]
      },
      {
        lineTitle: 'НОВІТНІ ТЮТЮНОВІ ВИРОБИ з капсулою',
        packsLine: [
          {
            title: 'L&M Special Purple',
            pack: '/images/packs/lm/special-purple.png',
            tobaccoIcon: '/images/lm-special/capsule-berries.png',
            tobaccoFirst: 'Містить капсулу з присмаком лісових ягід',
            paperIcon: '/images/lm-special/paper.png',
            paperText:
              'Загорнуті в сигаретний папір, який зроблений з відновленого тютюну',
            tobaccoSecond: '',
            filterIcon: '/images/lm-special/filter.png',
            background: `url("/images/lm-special/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість новітніх продуктів у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з двох <br /> частин:
                </p>
              </div>
            ),
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'L&M Special Red',
            pack: '/images/packs/lm/special-red.png',
            tobaccoIcon: '/images/lm-special/capsule-fruit.png',
            tobaccoFirst: 'Містить капсулу з присмаком кавуна',
            tobaccoSecond: '',
            paperIcon: '/images/lm-special/paper.png',
            paperText:
              'Загорнуті в сигаретний папір, який зроблений з відновленого тютюну',
            filterIcon: '/images/lm-special/filter.png',
            background: `url("/images/lm-special/background.png")`,
            characteristicListTitle: (
              <div>
                <p>
                  Оригінальний Recessed<sup>тм</sup> фільтр
                </p>
                <p>
                  Фільтр складається з двох <br /> частин:
                </p>
              </div>
            ),
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість новітніх продуктів у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    brandId: 'philip-morris',
    background: `url("/images/lm-double-splash/violet-background.png")`,
    brandTitle: 'PHILIP MORRIS',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'Philip Morris Red 25 Edition',
            pack: '/images/packs/philip-morris/red-25-edition.png',
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: '/images/pmn/filter.png',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 25'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 8'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з<br /> високоякісної целюлози,
                    який затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Philip Morris Blue 25 Edition',
            pack: '/images/packs/philip-morris/blue-25-edition.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/pmn/filter.png',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size ("Кінг сайз")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 25'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 8'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр з<br /> високоякісної целюлози,
                    який затримує тверді частинки сигаретного диму.
                  </span>
                ),
                bordered: false
              }
            ]
          }
          // {
          //   title: 'Philip Morris Red',
          //   pack: '/images/packs/philip-morris/red.png',
          //   tobaccoFirst: 'Вміст смол, мг - 9',
          //   tobaccoSecond: 'Вміст нікотину, мг - 0.7',
          //   filterIcon: '/images/pmn/filter.png',
          //   characteristicInfoList: [
          //     {
          //       title: 'Формат ',
          //       description: '- King Size Slim ("Кінг сайз")'
          //     },
          //     {
          //       title: 'Кількість сигарет у пачці ',
          //       description: '– 20'
          //     },
          //     {
          //       title: 'Кількість пачок у блоці ',
          //       description: '– 10'
          //     }
          //   ],
          //   filterCharacteristicList: [
          //     {
          //       description: (
          //         <span>
          //           Класичний ацетатний фільтр з<br /> високоякісної целюлози,
          //           який затримує тверді частинки сигаретного диму.
          //         </span>
          //       ),
          //       bordered: false
          //     }
          //   ]
          // }
        ]
      },
      {
        lineTitle: 'Компактна лінійка',
        packsLine: [
          {
            title: 'Philip Morris Novel Blue',
            pack: '/images/packs/philip-morris/novel-blue.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/pmn/filter.png',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр, який краще тримає форму та менше
                    розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Philip Morris Novel Silver',
            pack: '/images/packs/philip-morris/novel-silver.png',
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: '/images/pmn/filter.png',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: (
                  <span>
                    Класичний ацетатний фільтр, який краще тримає форму та менше
                    розмокає
                  </span>
                ),
                bordered: false
              }
            ]
          },
          {
            title: 'Philip Morris Novel Purple',
            pack: '/images/packs/philip-morris/novel-purple.png',
            background: `url("/images/lm-special/new-background.png")`,
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/parliament/filter.png',
            isNewMixture: false,
            newMixtureText: 'Особлива тютюнова суміш',
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
          // {
          //   title: 'Philip Morris Novel Mix Summer',
          //   pack: '/images/packs/philip-morris/novel-mix-summer.png',
          //   tobaccoFirst: 'Вміст смол, мг - 6',
          //   tobaccoSecond: 'Вміст нікотину, мг - 0.5',
          //   filterIcon: '/images/pmn/pmnms/filter.png',
          //   characteristicInfoList: [
          //     {
          //       title: 'Формат ',
          //       description: '- King Size Slim (Кінг сайз слім)'
          //     },
          //     {
          //       title: 'Кількість сигарет у пачці ',
          //       description: '– 20'
          //     },
          //     {
          //       title: 'Кількість пачок у блоці ',
          //       description: '– 10'
          //     }
          //   ],
          //   filterCharacteristicList: [
          //     {
          //       description: (
          //         <span>
          //           <b>Класичний ацетатний фільтр</b>
          //           <br /> з високоякісної целюлози, який затримує тверді
          //           частинки сигаретного диму. Містить капсулу зі смаком літніх
          //           соковитих фруктів.
          //         </span>
          //       ),
          //       bordered: false
          //     }
          //   ]
          // }
        ]
      }
    ]
  },
  {
    brandId: 'parliament',
    background: `url("/images/lm-double-splash/violet-background.png")`,
    brandTitle: 'Parliament',
    lines: [
      {
        lineTitle: 'Стандартна лінійка',
        packsLine: [
          {
            title: 'Parliament Carat Purple',
            pack: '/images/packs/parliament/carat-purple.png',
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/parliament/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 90-95мм Slims ("90-95мм слімc")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Night Blue',
            pack: '/images/packs/parliament/night-blue.png',
            tobaccoFirst: 'Вміст смол, мг - 9',
            tobaccoSecond: 'Вміст нікотину, мг - 0.7',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/parliament/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Aqua Blue',
            pack: '/images/packs/parliament/aqua-blue.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/parliament/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Silver Blue',
            pack: '/images/packs/parliament/silver-blue.png',
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/parliament/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Platinum',
            pack: '/images/packs/parliament/platinum.png',
            tobaccoFirst: 'Вміст смол, мг - 1',
            tobaccoSecond: 'Вміст нікотину, мг - 0.1',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/parliament/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size (Кінг сайз)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Лінійка Суперслімс',
        packsLine: [
          {
            title: 'Parliament Aqua Super Slims',
            pack: '/images/packs/parliament/aqua-super-slims.png',
            tobaccoFirst: 'Вміст смол, мг - 5',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/parliament/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 100 Super Slims ("100 супер слімc")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Silver Super Slims',
            pack: '/images/packs/parliament/silver-super-slims.png',
            tobaccoFirst: 'Вміст смол, мг - 3',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/parliament/background-new.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- 100 Super Slims ("100 супер слімc")'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
        ]
      },
      {
        lineTitle: 'Лінійка Soho NYC',
        packsLine: [
          {
            title: 'Parliament Soho Compact Blue',
            pack: '/images/packs/parliament/soho-compact-blue.png',
            tobaccoFirst: 'Вміст смол, мг - 6',
            tobaccoSecond: 'Вміст нікотину, мг - 0.5',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/parliament/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Soho Compact Silver',
            pack: '/images/packs/parliament/soho-compact-silver.png',
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.4',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/parliament/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Slim (Кінг сайз слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          },
          {
            title: 'Parliament Soho Vibe',
            pack: '/images/packs/parliament/soho-vibe.png',
            tobaccoFirst: 'Вміст смол, мг - 4',
            tobaccoSecond: 'Вміст нікотину, мг - 0.3',
            filterIcon: '/images/parliament/filter.png',
            background: `url("/images/parliament/background.png")`,
            characteristicInfoList: [
              {
                title: 'Формат ',
                description: '- King Size Super Slim (Кінг сайз супер слім)'
              },
              {
                title: 'Кількість сигарет у пачці ',
                description: '– 20'
              },
              {
                title: 'Кількість пачок у блоці ',
                description: '– 10'
              }
            ],
            filterCharacteristicList: [
              {
                description: <span>Ацетатна частина</span>,
                bordered: true
              },
              {
                description: <span>Вугільна частина</span>,
                bordered: true
              },
              {
                description: (
                  <span>
                    Ущільнена повітряна камера з ацетатною стінкою, що робить
                    смак м'яким і більш збалансованим
                  </span>
                ),
                bordered: true
              }
            ]
          }
        ]
      }
    ]
  }
];
