import React, { useState } from 'react';
import WelcomeScreen from './WelcomeScreen';
import DownloadPhotoScreen from './DownloadPhotoScreen';
import WebcamScreen from './WebcamScreen';
import ProgressBarScreen from './ProgressBarScreen';
import SuccessScreen from './SuccessScreen';
import ErrorScreen from './ErrorScreen';
import FinalScreen from './final-screen';
import OpenAI from 'openai';
import axios from 'axios';

const content = {
  title: 'Твій <br/> портрет-мурал',
  description:
    'Район Сохо в Нью-Йорку славиться своєю архітектурою і духом творчості. <br/><br/>Тож ми створили інструмент, що перетворить твоє фото на яскравий мурал за допомогою штучного інтелекту!',
  button: 'Почати'
};

export default function MuralChallenge({ setScreenNumber, backgroundImage }) {
  const [step, setStep] = useState(0);
  const [imgSrc, setImgSrc] = useState(null);
  const [completed, setCompleted] = useState(0);
  const [result, setResult] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });

  // const base64ToFile = (base64String) => {
  //   let arr = base64String.split(','),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], 'photo.png', { type: mime });
  // };

  const fetchMaskImage = async (isMan) => {
    try {
      const response = await axios.get(
        `images/mural/model/${isMan ? 'man' : 'woman'}/${
          Math.floor(Math.random() * 3) + 1
        }.jpg`,
        {
          responseType: 'arraybuffer'
        }
      );

      const base64String = Buffer.from(response.data, 'binary').toString(
        'base64'
      );
      return `data:${response.headers['content-type']};base64,${base64String}`;
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  async function generateImage(img) {
    setLoading(true);
    let counter = 0;
    const intervalID = setInterval(() => {
      setCompleted((prev) => (prev + 1 > 100 ? 100 : prev + 1));
      counter++;
    }, 200);
    try {
      const message = await openai.chat.completions.create({
        model: 'gpt-4-vision-preview',
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: 'Determine the gender of the person in the photo, is it a man? answer the question in one word, without punctuation marks and in lowercase, true or not?'
              },
              {
                type: 'image_url',
                image_url: {
                  url: img
                }
              }
            ]
          }
        ],
        max_tokens: 300
      });

      const isMan =
        message?.choices[0]?.message?.content.includes('true') || false;
      const targetImage = await fetchMaskImage(isMan);

      const swapResult = await axios.post(
        'https://api.goapi.xyz/api/face_swap/v1/async',
        {
          target_image: targetImage,
          swap_image: img,
          result_type: 'url'
        },
        {
          headers: {
            'X-API-Key':
              '7c4cb3e41b099f886a6c93d75c563175c82c3d091b9a62ed55cab30461f4916a'
          }
        }
      );

      let swapResultImage = null;
      let awaitFetchCount = 0;

      while (!swapResultImage) {
        if (counter < 100) {
          const result = await axios.post(
            'https://api.goapi.xyz/api/face_swap/v1/fetch',
            {
              task_id: swapResult?.data?.data?.task_id
            },
            {
              headers: {
                'X-API-Key':
                  '7c4cb3e41b099f886a6c93d75c563175c82c3d091b9a62ed55cab30461f4916a'
              }
            }
          );
          if (result?.data?.data?.image)
            swapResultImage = result?.data?.data?.image;

          awaitFetchCount = awaitFetchCount + 1;
        } else {
          setStep(4);
          return;
        }
      }

      setResult(swapResultImage);
      setStep((prev) => prev + 1);
    } catch (err) {
      console.error('Error: ', err);
    } finally {
      if (result) {
        clearInterval(intervalID);
      }
      setLoading(false);
    }
  }

  if (step === 0)
    return (
      <WelcomeScreen
        setScreenNumber={setScreenNumber}
        backgroundImage={backgroundImage}
        title={content.title}
        description={content.description}
        buttonTitle={content.button}
        setStep={setStep}
      />
    );
  if (step === 1)
    return (
      <DownloadPhotoScreen
        setScreenNumber={setScreenNumber}
        backgroundImage={backgroundImage}
        setStep={setStep}
      />
    );
  if (step === 2)
    return (
      <WebcamScreen
        step={step}
        setStep={setStep}
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        setCompleted={setCompleted}
        generateImage={generateImage}
      />
    );
  if (step === 3 && loading)
    return (
      <ProgressBarScreen
        setScreenNumber={setScreenNumber}
        backgroundImage={backgroundImage}
        step={step}
        setStep={setStep}
        completed={completed}
      />
    );
  if (step === 4 && result)
    return (
      <SuccessScreen
        setScreenNumber={setScreenNumber}
        backgroundImage={backgroundImage}
        step={step}
        setStep={setStep}
        result={result}
      />
    );
  if (step === 4 && !result)
    return (
      <ErrorScreen
        setScreenNumber={setScreenNumber}
        backgroundImage={backgroundImage}
        step={step}
        setStep={setStep}
      />
    );
  if (step === 5)
    return (
      <FinalScreen
        setScreenNumber={setScreenNumber}
        setNextScreenNumber={() => setScreenNumber(1)}
        backgroundImage={backgroundImage}
      />
    );
}
