import React, { useCallback } from 'react';
import styles from './index.module.scss';
import global from '../../../index.module.scss';
import HeadContainer from '../../../../common/head-container';
import { DataLayerEventContext } from '../../../../contexts/dataLayerEventContext';
import { useContext } from 'react';
import classNames from 'classnames';

const FinalScreen = ({
  setScreenNumber,
  setNextScreenNumber,
  setViewScreenNumber,
  backgroundImage,
  style = null
}) => {
  const { sendEvent } = useContext(DataLayerEventContext);
  const onButtonClickHandler = useCallback(() => {
    setNextScreenNumber();
    sendEvent('exit_challenge');
  }, [sendEvent, setNextScreenNumber]);

  return (
    <div
      className={classNames(
        styles.finalContainer,
        style ? styles[style] : null
      )}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentWrapper}>
        <div className={styles.imageContainer}>
          <img
            src="/images/mural/final_img.svg"
            alt="final"
            className={styles.finalImage}
          />
        </div>
        <p className={styles.finalTitle}>
          Тепер твій портрет <br />
          виконаний <br />у креативному стилі <br />
          Сохо!
        </p>
        <p className={styles.finalDescription}>
          Збережи його, щоб запам’ятати неповторну атмосферу креативності
          відомого району.
        </p>
        <button
          className={classNames(
            setViewScreenNumber ? global.reviewButton : global.menuButton,
            styles.button
          )}
          onClick={onButtonClickHandler}
        >
          Далі
        </button>
      </div>
    </div>
  );
};

export default FinalScreen;
