import React, { useCallback } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../common/head-container';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';
import { useContext } from 'react';
import classNames from 'classnames';

const FeedbackScreen = ({
  setScreenNumber,
  setNextScreenNumber,
  backgroundImage,
  eventName = null
}) => {
  const { sendEvent } = useContext(DataLayerEventContext);
  const onButtonClickHandler = useCallback(() => {
    setNextScreenNumber();
    sendEvent(eventName || 'start_challenge');
  }, [setScreenNumber, sendEvent]);

  return (
    <div
      className={styles.feedbackScreenContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentContainer}>
        <div className={styles.imageContainer}>
          <img
            src="/images/adventure/final_ellipse.png"
            alt="blue-ellipse"
            className={styles.ellipse}
          />
          <img
            src="/images/adventure/final_img.png"
            alt="woman"
            className={styles.finalImg}
          />
        </div>
        <p className={styles.resultTitle}>як тобі гра?</p>
        <p className={styles.resultText}>
          Пройди її ще, щоб відкрити інші кінцівки!
        </p>
        <button
          className={classNames(styles.button, global.menuButton)}
          onClick={onButtonClickHandler}
        >
          Грати ще
        </button>
      </div>
    </div>
  );
};

export default FeedbackScreen;
