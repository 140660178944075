import React from "react";
import styles from "./index.module.scss";
import HeadContainer from "../../../common/head-container";
import global from "../index.module.scss";

const ChallengeScreen = ({ setScreenNumber }) => {
  return (
    <div className={styles.challengeScreen}>
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.headContainer}>
        <img
          src="images/lm-double-splash/blue-ellipse.png"
          alt="blue-ellipse"
          className={styles.ellipse}
        />
        <p>Прагнеш нових відкриттів?</p>
      </div>
      <div className={styles.bottomContainer}>
        Кожен обирає щось своє. Хтось прагне нових відкриттів, а комусь не
        хочеться різноманіття.
      </div>
      <button
        className={global.menuButton}
        style={{ marginTop: 10 }}
        onClick={() => setScreenNumber(6)}
      >
        Пройди тест
      </button>
    </div>
  );
};

export default ChallengeScreen;
