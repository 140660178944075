import React from 'react';
import styles from './index.module.scss';

const CharacteristicPlusComponents = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <div className={styles.contentContainer}>
        <div className={styles.whiteContainer} />
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svg}
        >
          <rect x="9" width="3" height="21" fill="#0D0E3B" />
          <rect
            y="12"
            width="3"
            height="21"
            transform="rotate(-90 0 12)"
            fill="#0D0E3B"
          />
        </svg>
      </div>
    </div>
  );
};

export default CharacteristicPlusComponents;
