import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import HeadContainer from '../../../../common/head-container';
import Confetti from 'react-confetti';

const initialTiles = [
  {
    id: 1,
    img: '/images/special-capsules/memory-challenge/1.png',
    matched: false
  },
  {
    id: 2,
    img: '/images/special-capsules/memory-challenge/2.png',
    matched: false
  },
  {
    id: 3,
    img: '/images/special-capsules/memory-challenge/3.png',
    matched: false
  },
  {
    id: 4,
    img: '/images/special-capsules/memory-challenge/4.png',
    matched: false
  },
  {
    id: 5,
    img: '/images/special-capsules/memory-challenge/5.png',
    matched: false
  },
  {
    id: 6,
    img: '/images/special-capsules/memory-challenge/6.png',
    matched: false
  }
];

const MemoryChallenge = ({
  backgroundImage,
  setScreenNumber,
  handleNextButtonClick
}) => {
  const [tiles, setTiles] = useState(
    [...initialTiles, ...initialTiles].sort(() => Math.random() - 0.5)
  );
  const [openTiles, setOpenTiles] = useState([]);
  const [matchedTiles, setMatchedTiles] = useState([]);
  const [isWelcome, setIsWelcome] = useState(true);
  const [isCanResult, setIsCanResult] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [isSuccessAnimation, setIsSuccessAnimation] = useState(false);

  useEffect(() => {
    if (openTiles.length === 2) {
      const [firstIndex, secondIndex] = openTiles;
      if (tiles[firstIndex].img === tiles[secondIndex].img) {
        setMatchedTiles([...matchedTiles, firstIndex, secondIndex]);
        setOpenTiles([]);

        if ([...matchedTiles, firstIndex, secondIndex]?.length === 12) {
          setIsSuccessAnimation(true);
          setIsCanResult(true);
        }
      } else {
        setTimeout(() => setOpenTiles([]), 500);
      }
    }
  }, [openTiles, tiles]);

  const handleClick = (index) => {
    if (
      openTiles.length < 2 &&
      !openTiles.includes(index) &&
      !matchedTiles.includes(index)
    ) {
      setOpenTiles([...openTiles, index]);
    }
  };

  return (
    <div
      className={styles.content}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      {isWelcome && !isResult && (
        <div className={styles.welcomeContainer}>
          <div className={styles.circleContainer}>
            <img
              src="/images/special-capsules/memory-challenge/circle.png"
              alt="circle"
            />
            <p className={styles.circleText}>А в тебе хороша пам’ять?</p>
          </div>
          <div className={styles.description}>
            <p>
              Зіграй у нашу смачну гру й перевіримо!
              <br />
              <br />
            </p>
            <p>Твоя задача — знайти пару до усіх зображень.</p>
          </div>
          <button
            className={styles.menuButton}
            onClick={() => setIsWelcome(false)}
          >
            Почати
          </button>
        </div>
      )}
      {!isWelcome && !isResult && (
        <>
          <p className={styles.headTitle}>Знайди пару!</p>
          <div className={styles.gameBoard}>
            {tiles.map((tile, index) => (
              <div
                key={index}
                className={classNames(
                  styles.tile,
                  `${
                    openTiles.includes(index) || matchedTiles.includes(index)
                      ? styles.open
                      : ''
                  }`
                )}
                onClick={() => handleClick(index)}
              >
                <div className={styles.tileContent}>
                  {openTiles.includes(index) || matchedTiles.includes(index) ? (
                    <img
                      src={tile.img}
                      alt="tile"
                      className={styles.tileImage}
                    />
                  ) : (
                    <div className={styles.tileCover} />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.backButton}
              onClick={() => {
                setIsWelcome(true);
                setIsCanResult(false);
                setIsResult(false);
                setIsSuccessAnimation(false);
              }}
            >
              Назад
            </button>
            {isCanResult && (
              <button
                className={styles.menuButton}
                onClick={() => {
                  setIsResult(true);
                  setIsSuccessAnimation(false);
                }}
              >
                Далі
              </button>
            )}
          </div>
        </>
      )}
      {isSuccessAnimation && <Confetti tweenDuration={1500} />}
      {!isWelcome && isResult && (
        <div className={styles.result}>
          <div className={styles.imageContainer}>
            <img
              src="/images/special-capsules/memory-challenge/circle.png"
              alt="circle"
            />
            <img
              className={styles.human}
              src="/images/special-capsules/memory-challenge/result.png"
              alt="result"
            />
          </div>
          <p className={styles.title}>Вітаємо,</p>
          <p className={styles.description}>
            тобі вдалося знайти пару до усіх зображень! Аж захотілося
            посмакувати соковитим кавуном і солодкими лісовими ягодами, чи не
            так?
          </p>
          <button className={styles.menuButton} onClick={handleNextButtonClick}>
            Далі
          </button>
        </div>
      )}
    </div>
  );
};

export default MemoryChallenge;
