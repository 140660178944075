import React, { useState } from 'react';
import ImageSelectChallenge from '../../../../common/challenge-component/image-select-challenge';
import styles from './index.module.scss';
import HeadContainer from '../../../../common/head-container';
import { challengeList, resultList } from './const';
import ImageSelectContainer from './image-select-container';

const FourImageSelectChallenge = ({
  setScreenNumber,
  backgroundImage,
  handleAfterChallengeClick
}) => {
  const [isWelcome, setIsWelcome] = useState(true);
  const [isResultView, setIsResultView] = useState(false);
  const [isEndChallenge, setIsEndChallenge] = useState(false);
  const [resultData, setResultData] = useState(null);

  const uniqueElements = (arr) => {
    let result = [];

    for (let str of arr) {
      if (!result.includes(str)) {
        result.push(str);
      }
    }

    return result;
  };

  const setRandomResult = (min, max) => {
    const randomValue = Math.floor(Math.random() * 2);

    setResultData(resultList[[min, max][randomValue] - 1]);
    setIsResultView(true);
  };

  const handleSubmitQuiz = (result) => {
    let resultValues = {};
    result.forEach((item) => {
      resultValues[item.answerId] = (resultValues[item.answerId] || 0) + 1;
    });
    console.log(resultValues);
    if (resultValues[1] === 2 && resultValues[2] === 2) {
      setRandomResult(1, 2);
      return;
    }
    if (resultValues[1] === 2 && resultValues[3] === 2) {
      setRandomResult(1, 3);
      return;
    }
    if (resultValues[1] === 2 && resultValues[4] === 2) {
      setRandomResult(1, 4);
      return;
    }
    if (resultValues[2] === 2 && resultValues[3] === 2) {
      setRandomResult(2, 3);
      return;
    }
    if (resultValues[2] === 2 && resultValues[4] === 2) {
      setRandomResult(2, 4);
      return;
    }
    if (resultValues[3] === 2 && resultValues[4] === 2) {
      setRandomResult(3, 4);
      return;
    }

    for (let i = 1; i < 5; i++) {
      const isEqualValues = result.every(
        (elem) => elem.answerId === i.toString()
      );
      const isEqualButNotLastArray = result.filter(
        (item) => item.answerId === i.toString()
      );
      const isTwoEqual = isEqualButNotLastArray.length === 2;
      const uniquesValue = uniqueElements(result.map((item) => item.answerId));

      if (
        isEqualValues ||
        isEqualButNotLastArray.length >= 3 ||
        (isTwoEqual && uniquesValue.length - 1 === 3)
      ) {
        setResultData(resultList[i - 1]);
        setIsResultView(true);
        return;
      }
    }
  };

  return (
    <div className={styles.quizContainer} style={{ backgroundImage }}>
      <HeadContainer setScreenNumber={setScreenNumber} />
      {isWelcome && (
        <div className={styles.welcomeContainer}>
          <div className={styles.imageContainer}>
            <p>А який аромат твій?</p>
            <img src="/images/lm-special/special-circle.png" alt="circle" />
          </div>
          <div className={styles.description}>
            <p>
              Дай відповідь на простих 5 запитань, а ми підкажемо, який солодкий
              смак тобі підходить найбільше.
            </p>
          </div>
          <button
            className={styles.menuButton}
            style={{ marginTop: 10 }}
            onClick={() => setIsWelcome(false)}
          >
            Почати
          </button>
        </div>
      )}
      {!isWelcome && !isResultView && !isEndChallenge && (
        <ImageSelectContainer
          challengeList={challengeList}
          onSubmit={(result) => handleSubmitQuiz(result)}
        />
      )}
      {isResultView && resultData && !isWelcome && !isEndChallenge && (
        <div className={styles.resultContainer}>
          <p className={styles.resultTitle}>Твій аромат:</p>
          <p className={styles.resultTitleMain}>{resultData.title}</p>
          <img
            src={resultData.image}
            alt={resultData.id}
            className={styles.resultImage}
          />
          <p className={styles.resultDescription}>{resultData.description}</p>
          <button
            className={styles.menuButton}
            style={{ marginTop: 10 }}
            onClick={() => {
              setIsEndChallenge(true);
              setIsResultView(false);
              setIsWelcome(false);
            }}
          >
            Далі
          </button>
        </div>
      )}
      {isEndChallenge && !isResultView && !isWelcome && (
        <div className={styles.welcomeContainer}>
          <div className={styles.imageContainer}>
            <p>Як тобі результат?</p>
            <img src="/images/lm-special/special-circle.png" alt="circle" />
          </div>
          <div className={styles.description}>
            <p>
              Використай свій аромат у парфумах чи в пахощах для дому, аби
              підкреслити власний характер.
            </p>
          </div>
          <button
            className={styles.menuButton}
            style={{ marginTop: 10 }}
            onClick={handleAfterChallengeClick}
          >
            Далі
          </button>
        </div>
      )}
    </div>
  );
};

export default FourImageSelectChallenge;
