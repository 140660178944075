import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import global from '../index.module.scss';
import classNames from 'classnames';
import HeadContainer from '../head-container';
import CharacteristicPlusComponents from '../characteristic-plus-components';
import { ReactComponent as Shop } from '../../icons/shop.svg';
import { useCallback } from 'react';
import { DataLayerEventContext } from '../../contexts/dataLayerEventContext';
import { useContext } from 'react';
import useShopLinks from '../../hooks/useShopLinks';
import WarningFooter from '../../components/lm-d-s/warning-footer/WarningFooter';

const CharacteristicScreen = ({
  setScreenNumber,
  title,
  packImage,
  tobaccoFirst,
  tobaccoSecond,
  filterCharacteristicList,
  characteristicListTitle,
  infoList,
  backButtonTitle = 'Повернутися до меню',
  nextButtonTitle = 'Далі',
  handleBackButton,
  handleNextButton,
  backgroundImage,
  filterIcon,
  style
}) => {
  const { shopLink } = useShopLinks();
  const { sendEvent } = useContext(DataLayerEventContext);
  const compositionRef = useRef(null);
  const filterRef = useRef(null);
  const packRef = useRef(null);
  const containerRef = useRef(null);
  const buyOnlineContainerRef = useRef(null);
  const [isOpenScrollTopButton, setIsOpenScrollTopButton] = useState(false);
  const [parentContainer, setParentContainer] = useState(null);

  useEffect(() => {
    const mainPageContainer = document.getElementById(
      'characteristic-parent-container-common'
    );

    if (mainPageContainer) setParentContainer(mainPageContainer);

    const onScroll = () => {
      compositionRef.current.getBoundingClientRect().y <= 50
        ? setIsOpenScrollTopButton(true)
        : setIsOpenScrollTopButton(false);
    };

    mainPageContainer.addEventListener('scroll', onScroll);
    return () => mainPageContainer.removeEventListener('scroll', onScroll);
  }, []);

  const onNextButtonClick = useCallback(() => {
    handleNextButton();
    sendEvent('characteristic_next');
  }, [sendEvent, handleNextButton]);

  return (
    <div className={styles.container}>
      <div
        className={styles.characteristicContainer}
        ref={containerRef}
        id="characteristic-parent-container-common"
      >
        <div className={styles.content}>
          <HeadContainer setScreenNumber={setScreenNumber} />
          <img
            src={backgroundImage}
            alt="background"
            className={styles.background}
            style={style}
          />
          <img
            src="/images/lm-double-splash/scroll-top-button.png"
            alt="scrollTopButton"
            className={classNames(
              styles.scrollTopButton,
              isOpenScrollTopButton && styles.visibleScrollTopButton
            )}
            onClick={() =>
              parentContainer.scrollTo({ top: 0, behavior: 'smooth' })
            }
          />
          <span className={styles.title}>{title}</span>
          <div className={styles.packContainer}>
            <img
              src={packImage}
              className={styles.packImage}
              alt="pack-image"
            />
            <CharacteristicPlusComponents
              onClick={() =>
                parentContainer.scrollTo(
                  0,
                  compositionRef.current.offsetTop - 10
                )
              }
              className={classNames(styles.plusIcon, styles.firstLeftPlus)}
            />
            <CharacteristicPlusComponents
              onClick={() =>
                parentContainer.scrollTo(0, packRef.current.offsetTop - 10)
              }
              className={classNames(styles.plusIcon, styles.secondLeftPlus)}
            />
            <CharacteristicPlusComponents
              onClick={() =>
                parentContainer.scrollTo(0, filterRef.current.offsetTop - 10)
              }
              className={classNames(styles.plusIcon, styles.rightPlus)}
            />
          </div>
          <div className={styles.buy_online}>
            <div className={styles.buy_online_copy}>
              Дізнатися, де купити онлайн
            </div>
            <div
              className={styles.buy_online_button}
              onClick={() =>
                parentContainer.scrollTo(
                  0,
                  buyOnlineContainerRef.current.offsetTop - 10
                )
              }
            >
              <Shop />
            </div>
          </div>
          <div
            id="compositionContainer"
            className={styles.compositionContainer}
            ref={compositionRef}
          >
            <img
              className={styles.icon}
              src="images/lm-double-splash/tobacco.png"
              alt="tobacco-icon"
            />
            <p>{tobaccoFirst}</p>
            <p>{tobaccoSecond}</p>
          </div>
          <div
            id="filterContainer"
            className={styles.filterContainer}
            ref={filterRef}
          >
            <img className={styles.icon} src={filterIcon} alt="first-plus" />
            {characteristicListTitle && (
              <div className={styles.characteristicListTitle}>
                {characteristicListTitle.title}
              </div>
            )}
            {filterCharacteristicList.map((item, idx) => (
              <p
                key={idx}
                style={item.bordered ? undefined : { border: 'none' }}
              >
                {item.title} {item.description}
              </p>
            ))}
          </div>
          <div
            id="packContainer"
            className={styles.filterContainer}
            ref={packRef}
          >
            <img
              className={styles.icon}
              src="images/lm-double-splash/pack-info.png"
              alt="first-plus"
            />
            <div className={styles.filterInfoBlock}>
              {infoList.map((item, idx) => (
                <span key={idx}>
                  <span>{item.title}</span>
                  {item.description}
                </span>
              ))}
            </div>
          </div>
          <div ref={buyOnlineContainerRef} className={styles.online_shop}>
            <div className={styles.online_shop_text}>
              Актуальний асортимент можна знайти на офіційних онлайн сервісах
              або у роздрібних точках продажу в твоєму місті
            </div>
            <div className={styles.online_shop_actions}>
              <div
                className={styles.online_shop_actions_button}
                style={{
                  background:
                    'linear-gradient(180deg, #B732FF 0%, #7A1FAB 100%)'
                }}
                onClick={() => window.open(shopLink.cigPoshta, '_blank')}
              >
                Cig-poshta
              </div>
              <div
                className={styles.online_shop_actions_button}
                style={{
                  background:
                    'linear-gradient(180deg, #32D33A 0%, #118317 100%)'
                }}
                onClick={() => window.open(shopLink.rozetka, '_blank')}
              >
                Rozetka
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={global.menuButton} onClick={handleBackButton}>
              {backButtonTitle}
            </button>
            <button
              className={global.reviewButton}
              style={{ marginTop: 14 }}
              onClick={onNextButtonClick}
            >
              {nextButtonTitle}
            </button>
          </div>
        </div>
        <WarningFooter screenNumber={0} />
      </div>
    </div>
  );
};

export default CharacteristicScreen;
