import React, { useContext } from "react";
import styles from "./SessionEndScreen.module.scss";
import { ScreensContext } from "../../contexts/screensContext";
import { ReactComponent as CompletedImage } from "../../icons/complited_journey.svg";
import { ReactComponent as NotConfirmedAgeImage } from "../../icons/not_confirmed_age.svg";
import ChooseStepBtn from "../chooseStepBtn/ChooseStepBtn";

export default function SessionEndScreen({
  setScreenNumber,
  customText,
  customHeader,
  customSVGStyles,
  prevScreenNumber,
}) {
  const screensContext = useContext(ScreensContext);
  const customTextMaybe =
    typeof customText === "string"
      ? customText
      : "Розпочни свою незабутню подорож прямо зараз!";

  const backBtnClickHandle = () => {
    setScreenNumber(prevScreenNumber || 4);
  };

  if (screensContext.endSessionType === "OPEN_NOT_FROM_QR") {
    return (
      <div className={styles.desktopBunner}>
        <div className={styles.desktopBunnerWr}>
          <div className={styles.hellowTitle}>
            Раді бачити на yourjourney.com.ua
          </div>
          <div className={styles.hellowMessage}>
            Скануй свій QR-код, щоб отримати більше!
          </div>
        </div>
      </div>
    );
  }

  if (screensContext.endSessionType === "NOT_MOBILE") {
    return (
      <div className={styles.noMobileView}>
        <div className={styles.contentContainer}>
          <span>Привіт!</span>
          <span>Цей контент доступний тільки з мобільного</span>
          <img
            src="images/lm-double-splash/mobile-icon-white.png"
            className={styles.phone}
          />
          <img
            src="images/lm-double-splash/no-mobile-lines.png"
            className={styles.lines}
          />
          <img
            src="images/lm-double-splash/gradient-circle.png"
            className={styles.circleNoMobile}
          />
        </div>
      </div>
    );
  } else if (screensContext.endSessionType === "NOT_CONFIRM_AGE") {
    return (
      <div className={styles.desktopBunner}>
        <div className={styles.desktopBunnerWr}>
          <NotConfirmedAgeImage className={styles.notConfirmedImage} />
          <div className={styles.notConfirmedMessage}>
            Цей сайт містить інформацію про тютюновмісний продукт і призначений
            для осіб старше 18 років, які споживають тютюн у тій чи іншій формі
            та проживають на території України.
          </div>
          <div className={styles.notConfirmedTitle}>
            Дякуємо за відвідування!
          </div>
        </div>
      </div>
    );
  } else if (screensContext.endSessionType === "SESSION_END") {
    return (
      <div className={styles.desktopBunner}>
        <div className={styles.desktopBunnerWr}>
          <h2 className={styles.complitedTitle}>
            {customHeader || "Дякуємо за відвідування!"}
          </h2>
          <CompletedImage
            className={
              customSVGStyles && styles[customSVGStyles]
                ? styles[customSVGStyles]
                : styles.complitedImage
            }
          />
          <div className={styles.sessionEndText}>{customTextMaybe}</div>
        </div>
        <ChooseStepBtn direction={"back"} execute={backBtnClickHandle} />
      </div>
    );
  }
}
