import React from "react";
import styles from "./index.module.scss";
import global from "../index.module.scss";
import HeadContainer from "../../../common/head-container";

const FinalQuizScreen = ({ setScreenNumber }) => {
  return (
    <div className={styles.finalScreen}>
      <HeadContainer setScreenNumber={setScreenNumber} />
      <img
        src="/images/lm-double-splash/challenge/finally-result.png"
        alt="finally-result-image"
      />
      <div className={styles.contentContainer}>
        <p>
          У L&M Loft Double Splash дві капсули, які можна комбінувати на свій
          смак!
        </p>
        <p>
          Це значить, що ти можеш обрати капсули зі смаком зелених соковитих
          фруктів, літнього нектару або ж поєднати їх між собою!
        </p>
        <p>
          Можна навіть не активувати жодну капсулу і відчути оригінальний смак
          тютюну.
        </p>
      </div>
      <button className={global.menuButton} onClick={() => setScreenNumber(13)}>
        Далі
      </button>
    </div>
  );
};

export default FinalQuizScreen;
