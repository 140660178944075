import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import global from '../../index.module.scss';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useContext } from 'react';
import { DataLayerEventContext } from '../../../contexts/dataLayerEventContext';

const WelcomeChallengeScreen = ({
  setScreenNumber,
  title,
  subtitle,
  buttonTitle = 'Дивитися',
  description,
  backgroundImage,
  setNextScreenNumber,
  eventName = null,
  style = null
}) => {
  const { sendEvent } = useContext(DataLayerEventContext);
  const onButtonClickHandler = useCallback(() => {
    setNextScreenNumber();
    sendEvent(eventName || 'start_challenge');
  }, [setNextScreenNumber, sendEvent]);
  return (
    <div
      className={classNames(
        styles.challengeScreen,
        style ? styles[style] : null
      )}
      style={{ backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentContainer}>
        <div className={styles.headContainer}>
          <img
            src="/images/common/blue-ellipse.png"
            alt="blue-ellipse"
            className={styles.ellipse}
          />
          <p>{title}</p>
          {subtitle && <span>{subtitle}</span>}
        </div>
        <div className={styles.bottomContainer}>
          <span className={styles.descrition}>{description}</span>
          <button
            className={classNames(styles.button, global.menuButton, {
              marginTop: 10
            })}
            style={{ marginTop: 10 }}
            onClick={onButtonClickHandler}
          >
            {buttonTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeChallengeScreen;
