import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../lm-d-s/head-container';
import WarningFooter from '../../lm-d-s/warning-footer/WarningFooter';

const lineList = [
  {
    title: 'L&M Special Purple',
    pack: '/images/packs/lm/special-purple.png',
    tobaccoIcon: '/images/lm-special/capsule-berries.png',
    tobaccoFirst: 'Містить капсулу з присмаком лісових ягід',
    paperIcon: '/images/lm-special/paper.png',
    paperText: 'Загорнуті в обгортку з відновленого тютюну',
    tobaccoSecond: '',
    filterIcon: '/images/lm-special/filter.png',
    background: `url("/images/lm-special/background.png")`,
    capsuleText: 'Містить капсулу з присмаком лісових ягід',
    characteristicInfoList: [
      {
        title: 'Формат ',
        description: '- King Size Slim (Кінг сайз слім)'
      },
      {
        title: 'Кількість новітніх виробів у пачці ',
        description: '– 20'
      },
      {
        title: 'Кількість пачок у блоці ',
        description: '– 10'
      }
    ],
    characteristicListTitle: (
      <div>
        <p>
          Оригінальний Recessed<sup>тм</sup> фільтр
        </p>
        <p>
          Фільтр складається з двох <br /> частин:
        </p>
      </div>
    ),
    filterCharacteristicList: [
      {
        description: <span>Ацетатна частина</span>,
        bordered: true
      },
      {
        description: (
          <span>
            Ущільнена повітряна камера з ацетатною стінкою, що робить смак
            м'яким і більш збалансованим
          </span>
        ),
        bordered: true
      }
    ]
  },
  {
    title: 'L&M Special Red',
    pack: '/images/packs/lm/special-red.png',
    tobaccoIcon: '/images/lm-special/capsule-fruit.png',
    tobaccoFirst: 'Містить капсулу з присмаком кавуна',
    tobaccoSecond: '',
    paperIcon: '/images/lm-special/paper.png',
    paperText: 'Загорнуті в обгортку з відновленого тютюну',
    filterIcon: '/images/lm-special/filter.png',
    background: `url("/images/lm-special/background.png")`,
    characteristicInfoList: [
      {
        title: 'Формат ',
        description: '- King Size Slim (Кінг сайз слім)'
      },
      {
        title: 'Кількість новітніх виробів у пачці ',
        description: '– 20'
      },
      {
        title: 'Кількість пачок у блоці ',
        description: '– 10'
      }
    ],
    characteristicListTitle: (
      <div>
        <p>
          Оригінальний Recessed<sup>тм</sup> фільтр
        </p>
        <p>
          Фільтр складається з двох <br /> частин:
        </p>
      </div>
    ),
    filterCharacteristicList: [
      {
        description: <span>Ацетатна частина</span>,
        bordered: true
      },
      {
        description: (
          <span>
            Ущільнена повітряна камера з ацетатною стінкою, що робить смак
            м'яким і більш збалансованим
          </span>
        ),
        bordered: true
      }
    ]
  },
  {
    title: 'Chesterfield Special Purple',
    pack: '/images/packs/chesterfield/special-purple.png',
    tobaccoSecond: '',
    tobaccoIcon: '/images/lm-special/capsule-berries.png',
    tobaccoFirst: 'Містить капсулу з присмаком лісових ягід',
    paperIcon: '/images/lm-special/paper.png',
    paperText: 'Загорнуті в обгортку з відновленого тютюну',
    filterIcon: '/images/lm-special/filter.png',
    background: `url("/images/lm-special/background.png")`,
    characteristicInfoList: [
      {
        title: 'Формат ',
        description: '- King Size Slim (Кінг сайз слім)'
      },
      {
        title: 'Кількість новітніх виробів у пачці ',
        description: '– 20'
      },
      {
        title: 'Кількість пачок у блоці ',
        description: '– 10'
      }
    ],
    characteristicListTitle: (
      <div>
        <p>
          Оригінальний Recessed<sup>тм</sup> фільтр
        </p>
        <p>
          Фільтр складається з двох <br /> частин:
        </p>
      </div>
    ),
    filterCharacteristicList: [
      {
        description: <span>Ацетатна частина</span>,
        bordered: true
      },
      {
        description: (
          <span>
            Ущільнена повітряна камера з ацетатною стінкою, що робить смак
            м'яким і більш збалансованим
          </span>
        ),
        bordered: true
      }
    ]
  },
  {
    title: 'Chesterfield Special Red',
    pack: '/images/packs/chesterfield/special-red.png',
    tobaccoIcon: '/images/lm-special/capsule-fruit.png',
    tobaccoFirst: 'Містить капсулу з присмаком кавуна',
    paperIcon: '/images/lm-special/paper.png',
    paperText: 'Загорнуті в обгортку з відновленого тютюну',
    tobaccoSecond: '',
    filterIcon: '/images/lm-special/filter.png',
    background: `url("/images/lm-special/background.png")`,
    characteristicInfoList: [
      {
        title: 'Формат ',
        description: '- King Size Slim (Кінг сайз слім)'
      },
      {
        title: 'Кількість новітніх виробів у пачці ',
        description: '– 20'
      },
      {
        title: 'Кількість пачок у блоці ',
        description: '– 10'
      }
    ],
    characteristicListTitle: (
      <div>
        <p>
          Оригінальний Recessed<sup>тм</sup> фільтр
        </p>
        <p>
          Фільтр складається з двох <br /> частин:
        </p>
      </div>
    ),
    filterCharacteristicList: [
      {
        description: <span>Ацетатна частина</span>,
        bordered: true
      },
      {
        description: (
          <span>
            Ущільнена повітряна камера з ацетатною стінкою, що робить смак
            м'яким і більш збалансованим
          </span>
        ),
        bordered: true
      }
    ]
  }
];

const ArrowSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="7"
      viewBox="0 0 4 7"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.816411 0.139209L3.85993 3.16392C4.04669 3.34953 4.04669 3.65047 3.85993 3.83608L0.81641 6.86079C0.629645 7.0464 0.326839 7.0464 0.140073 6.86079C-0.0466919 6.67518 -0.0466919 6.37424 0.140073 6.18863L2.84542 3.5L0.140074 0.811367C-0.0466914 0.625755 -0.0466914 0.32482 0.140074 0.139209C0.326839 -0.0464028 0.629646 -0.0464028 0.816411 0.139209Z"
        fill="white"
      />
    </svg>
  );
};

const WelcomeScreen = ({ setScreenNumber, handleSelectPack }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <HeadContainer setScreenNumber={setScreenNumber} />
        <p className={styles.title}>L&M та Chesterfield капсулою!</p>
        <div className={styles.packList}>
          {lineList.map((pack) => (
            <div
              className={styles.packItem}
              onClick={() => handleSelectPack(pack)}
            >
              <img src={pack?.pack} alt={pack?.title} />
              <div className={styles.name}>
                <span>{pack?.title}</span>
                <ArrowSvg />
              </div>
            </div>
          ))}
        </div>
        <p className={styles.description}>
          Новітні тютюнові вироби в обгортці з відновленого тютюну
        </p>
      </div>
      <div className={styles.footer}>
        <WarningFooter />
      </div>
    </div>
  );
};

export default WelcomeScreen;
