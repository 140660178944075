import React, { useState, useMemo } from 'react';
import HeadContainer from '../../head-container';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import classNames from 'classnames';

export default function ErrorScreen({
  setScreenNumber,
  backgroundImage,
  setStep
}) {
  const prevClick = () => {
    setStep((prev) => prev - 1);
  };

  const nextClick = () => {
    setStep(1);
  };

  return (
    <div
      className={styles.ErrorContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.contentContainer}>
        <div className={styles.headContainer}>
          <div className={styles.image}>
            <img src="/images/mural/error_img.svg" alt="result" />
          </div>
          <p className={styles.title}>
            Ой! Щось пішло не так…
            <br />
            <br />
            Будь ласка, спробуй
            <br />
            ще раз.
          </p>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.buttonsRow}>
            <button
              className={classNames(styles.button, global.reviewButton)}
              onClick={prevClick}
            >
              Назад
            </button>
            <button
              className={classNames(styles.button, global.menuButton)}
              onClick={nextClick}
            >
              Ще раз
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
