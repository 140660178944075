import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import HeadContainer from '../../head-container';

const AdviceChallenge = ({
  challengeList,
  setScreenNumber,
  backgroundImage,
  setScreenNumberAfterResult,
  withCounter = true,
  style = null
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(challengeList[0]);

  return (
    <div
      className={classNames([
        styles.imageSelectContainer,
        !withCounter && styles.withoutCounter,
        style ? styles[style] : null
      ])}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      <div className={styles.stepToolbar}>
        {challengeList.map((item, index) => (
          <div
            className={classNames(
              styles.stepItem,
              index === challengeStep && styles.activeStepItem
            )}
            key={index}
          />
        ))}
      </div>
      {selectQuestion && (
        <div className={styles.questionItem}>
          <div className={styles.titleWr}>
            {withCounter && (
              <div className={styles.challengeStep}>{challengeStep + 1}</div>
            )}
            <span className={styles.titleQuestion}>{selectQuestion.title}</span>
          </div>
          <div className={styles.adviceWrapper}>
            <img src={selectQuestion.image} alt="#" />
            <div className={styles.descriptionContainer}>
              {selectQuestion.description}
            </div>
          </div>
        </div>
      )}
      <div className={styles.buttonContainer}>
        {challengeStep !== 0 && (
          <button
            className={global.reviewButton}
            onClick={() => {
              setSelectQuestion(challengeList[challengeStep - 1]);
              setChallengeStep(challengeStep - 1);
            }}
          >
            Назад
          </button>
        )}
        <button
          className={global.menuButton}
          onClick={() => {
            if (challengeList.length > challengeStep + 1) {
              setSelectQuestion(challengeList[challengeStep + 1]);
              setChallengeStep(challengeStep + 1);
            } else {
              setScreenNumberAfterResult();
            }
          }}
        >
          Далі
        </button>
      </div>
    </div>
  );
};

export default AdviceChallenge;
