import React from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../../components/lm-d-s/head-container';
import WarningFooter from '../../../common/warning-footer/WarningFooter';

const ProhibitedChapterScreen = ({
  setScreenNumber,
  setReviewScreenNumber
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.authorityContainer}>
        <div style={{ padding: '20px 30px 0' }}>
          <HeadContainer setScreenNumber={setScreenNumber} />
        </div>
        <span className={styles.title}>Сигарети з капсулами забороняють?</span>
        <span
          className={styles.description}
          style={{ marginTop: 26, padding: '0 30px' }}
        >
          Якщо коротко, то так, в Україні було ухвалено закон про заборону на
          виробництво (крім виробництва для експорту), імпорт та подальший
          продаж цигарок зі смаковими добавками. Він набуває чинності з 11.07
          2023. Це означає, що починаючи з 11 липня їх виробництво та імпорт
          буде зупинено.
        </span>
        <span
          className={styles.description}
          style={{ marginTop: 26, padding: '0 30px' }}
        >
          Тож в ринку залишиться лише той продукт, що був виготовнений раніше. І
          хоча сигарети з капсулами не зникнуть з полиць магазинів відразу,
          враховуючи заборону на виробництво, з часом залишаться лише сигарети
          без ароматичних домішок.
        </span>
        <div className={styles.blockContainer} style={{ marginTop: 40 }}>
          <span className={styles.blockTitle}>
            Які сигарети та інші тютюнові продукти заборонені з липня 2023 року?
          </span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            З 11 липня 2023 року заборонені такі ароматизовані продукти:
          </span>
          <div className={styles.list}>
            <div className={styles.listItem}>
              сигарети із капсулами різних смаків (фруктові, ментолові, будь-які
              інші)
            </div>
            <div className={styles.listItem}>
              ароматизовані сигарети, в тому числі з ментолом, які не мають
              капсул
            </div>
            <div className={styles.listItem}>
              тютюн для самокруток із характерним смаком та/або запахом диму
            </div>
            <div className={styles.listItem}>
              електронні сигарети, заправні контейнери, рідини, що
              використовуються в електронних сигаретах із характерним запахом
              та/або смаком.
            </div>
          </div>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Не має значення, яка частина сигарети містить ароматизований
            компонент (фільтр, капсула, тютюн, папір). Додавання будь-якого
            додаткового аромату до тютюну заборонено з 11 липня 2023 року.
          </span>
        </div>
        <div className={styles.blockContainer} style={{ marginTop: 40 }}>
          <span className={styles.blockTitle}>
            Чи можна зараз купити сигарети з капсулами в Україні?
          </span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Так, зараз сигарети з капсулами продовжують продаватися. Сигарети з
            капсулами можна купити онлайн на cig-poshta.in.ua чи на
            rozetka.com.ua., а також в інших офіційних реселлерів.
          </span>
        </div>
        <div className={styles.blockContainer} style={{ marginTop: 40 }}>
          <span className={styles.blockTitle}>
            Коли будуть заборонені сигарети з капсулами в Україні?
          </span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Закон про заборону сигарет із капсулами набув чинності 11 липня 2023
            року. З цього моменту капсульні та ароматизовані сигарети буде
            заборонено виготовляти та імпортувати, проте до 11 липня 2024 року
            буде дозволений продаж сигарет, які були виготовлені раніше.
          </span>
        </div>
        <div className={styles.blockContainer} style={{ marginTop: 40 }}>
          <span className={styles.blockTitle}>
            Навіщо заборонили цигарки із капсулами?
          </span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            Закон України від 16.12.2021 № 1978-IX “Про внесення змін до деяких
            законів України щодо охорони здоров’я населення від шкідливого
            впливу тютюну” – це ще один крок у послідовній програмі приведення
            українського законодавства у тютюновій сфері у відповідність до
            європейського.
          </span>
          <span className={styles.description} style={{ marginTop: 13 }}>
            У країнах Євросоюзу та у Великій Британії продаж ароматизованих
            цигарок, включаючи ментолові, заборонено ще з 2020 року.
          </span>
        </div>

        <div
          className={styles.blockContainer}
          style={{ margin: '20px 0 35px 0' }}
        >
          <button
            className={styles.menuButton}
            onClick={() => setScreenNumber(1)}
          >
            Повернутись до меню
          </button>
          <button
            className={styles.reviewButton}
            style={{ marginTop: 14 }}
            onClick={setReviewScreenNumber}
          >
            Далі
          </button>
        </div>
      </div>
      <WarningFooter backgroundColor="#491585" />
    </div>
  );
};

export default ProhibitedChapterScreen;
